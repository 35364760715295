import { useEffect, useState } from "react";
import ItemService from "../services/ItemService";

export const useCollectionItems = (collectionId) => {
    const [collectionItems, setCollectionItems] = useState([]);
    const [isCollectionItemsLoading, setIsCollectionItemsLoading] = useState(false);

    useEffect(() => {
        if (!collectionId) {
            return;
        }
        
        const getCollections = async () => {
            setIsCollectionItemsLoading(true);

            try {
                const { data } = await ItemService.getByCollectionId(collectionId);

                setCollectionItems(data);
            } catch(_) {
                setCollectionItems([]);
            }

            setIsCollectionItemsLoading(false);
        };

        getCollections();
    }, [collectionId]);

    return { collectionItems, isCollectionItemsLoading };
};
