import { createContext, useContext } from 'react';
import { useCategories } from '../hooks/useCategories';

export const CategoriesContext = createContext([]);

export const CategoriesProvider = (props) => {
    const { children } = props;

    const { categories, isLoading } = useCategories();

    return (
        <CategoriesContext.Provider value={{categories, isLoading}}>
            {children}
        </CategoriesContext.Provider>
    );
}

export const useCategoriesContext = () => {
    return useContext(CategoriesContext);
}
