import React from "react";
import cn from "classnames";
import styles from "./SuccessfullyPurchased.module.sass";
import Icon from "../../../../components/Icon";
import { Trans, useTranslation } from "react-i18next";

const socials = [
  {
    title: "facebook",
    url: "https://www.facebook.com/",
  },
  {
    title: "twitter",
    url: "https://twitter.com/",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/",
  },
  {
    title: "pinterest",
    url: "https://www.pinterest.com/",
  },
];

const SuccessfullyPurchased = ({ className, nft }) => {
  const { t } = useTranslation('Item');

  return (
    <div className={cn(className, styles.wrap)}>
      <div className={cn("h2", styles.title)}>
        <Trans i18nKey="SuccessfullyPurchased.title" t={t} components={[<span role="img" aria-label="firework"/>]}/>
      </div>
      <div className={styles.info}>
        <Trans i18nKey="SuccessfullyPurchased.info" t={t} values={{ metadataName: nft?.metadata?.name, name: "kvell" }} components={[<span/>]} />
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>{t('SuccessfullyPurchased.table.status')}</div>
          <div className={styles.col}>{t('SuccessfullyPurchased.table.transactionId')}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>{t('SuccessfullyPurchased.table.processing')}</div>
          <div className={styles.col}>0msx836930...87r398</div>
        </div>
      </div>
      <div className={styles.stage}>{t('SuccessfullyPurchased.stage')}</div>
      <div className={styles.socials}>
        {socials.map((x, index) => (
          <a
            className={styles.social}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <Icon name={x.title} size="24" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SuccessfullyPurchased;
