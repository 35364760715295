import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Icon from "../Icon";
import { Trans, useTranslation } from "react-i18next";

const initialItems = [
  {
    title: "info",
    menu: [
      {
        title: "faq",
        url: "/faq",
      },
      {
        title: "support",
        url: "/support",
      },
    ],
  },
];

const socials = [
  {
    icon: 'instagram',
    url: 'https://instagram.com/kvell.times?igshid=YmMyMTA2M2Y='
  },
  {
    icon: 'telegram',
    url: 'https://t.me/kvell_times',
    viewBox: '0 0 30 30'
  },
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/khusan.umarov.7/'
  },
];

const Footers = () => {
  const { t, ready } = useTranslation('Footer');
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    if (!ready) {
      return;
    }

    setItems(items.map((item) => ({
      ...item,
      ...{
        title: t(`nav.titles.${item.title}`),
        menu: item.menu.map((menuItem) => ({
          ...menuItem,
          ...{ title: t(`nav.items.${menuItem.title}`)},
        })),
    }})));
  }, [ready]);

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-dark.png"
                srcDark="/images/logo-light.png"
                alt="KVELL.NFT"
              />
            </Link>
            <div className={styles.info}>{t('info')}</div>
            <div className={styles.socialsItems}>
              {socials.map((x, index) => (
                <a
                  href={x.url}
                  className={styles.socialsLink}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <Icon name={x.icon} viewBox={x.viewBox} />
                </a>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            <div className={styles.contactsTitle}>{t('contacts.title')}</div>
            <div className={styles.contactsItems}>
              <div className={styles.contactsItem}>
                <div className={styles.contactsItemTitle}>
                  {t('contacts.address.title')}
                </div>
                <div className={styles.contactsItemData}>
                  <a
                    href="https://goo.gl/maps/uiGND1quWiQR1XaF6"
                    target="_blank"
                    rel="noreferrer"
                  >{t('contacts.address.data')}</a>
                </div>
              </div>
              <div className={styles.contactsItem}>
                <div className={styles.contactsItemTitle}>
                  {t('contacts.email.title')}
                </div>
                <div className={styles.contactsItemData}>
                  <a href="mailto:info@kvell.group">info@kvell.group</a>
                </div>
              </div>
              <div className={styles.contactsItem}>
                <div className={styles.contactsItemTitle}>
                  {t('contacts.phone.title')}
                </div>
                <div className={styles.contactsItemData}>
                  <a href="tel:+74951202250">+7 (495) 120-22-50</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            <Trans i18nKey="copyright" t={t} components={[<a href="https://kvell.group" target="_blank" rel="noreferrer"/>]} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
