import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
import { ru } from 'date-fns/locale';
import { RU_LANGUAGE_CODE } from '../constants/i18n';

export const useDate = () => {
    const { i18n } = useTranslation();

    return {
        formatDate: (dateValue, dateFormat) => format(dateValue, dateFormat, {
            locale: i18n.language === RU_LANGUAGE_CODE ? ru : null
        })
    }
}