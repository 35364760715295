import CollectionRepository from "../repositories/CollectionRepository";

function prepareFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    let value = data[key];

    if (typeof value === 'object' && !(value instanceof File)) {
      value = JSON.stringify(value);
    }

    if (value === undefined) {
      continue;
    }

    formData.append(key, value);
  }

  return formData;
}

const CollectionService = {
  create(data) {
    return CollectionRepository.create(prepareFormData(data));
  },

  update(id, data) {
    return CollectionRepository.update(id, prepareFormData(data));
  },

  getOne(query) {
    return CollectionRepository.getOne(query);
  },

  getMany() {
    return CollectionRepository.getMany();
  },

  delete(id) {
    return CollectionRepository.delete(id);
  }
}

export default CollectionService;