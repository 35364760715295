import React, {useState} from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import Checkout from "./Checkout";
import SuccessfullyPurchased from "./SuccessfullyPurchased";
import Modal from "../../../components/Modal";
import PutSale from "./PutSale";
import { useTranslation } from "react-i18next";

const Control = ({className, nft, isOnSale, isMyItem}) => {
    const [visibleModalPurchase, setVisibleModalPurchase] = useState(false);
    const [visibleModalPutSale, setVisibleModalPutSale] = useState(false);
    const [successPurchase, setSuccessPurchase] = useState(false);
    const { t } = useTranslation('Item');

    function onButtonClick() {
        if (isMyItem) {
            if (!isOnSale) setVisibleModalPutSale(true);
        } else {
            if (isOnSale) setVisibleModalPurchase(true);
        }
    }

    function buttonText() {
        if (isOnSale && !isMyItem) return t('Control.buyNow');
        if (!isOnSale && isMyItem) return t('Control.putOnSale');
    }

    return (
        <>
            <div className={cn(styles.control, className)}>
                {isOnSale && (<div className={styles.head}>
                        <div className={styles.details}>
                            <div className={styles.info}>
                                {t('Control.info')}
                            </div>
                            <div className={styles.cost}>
                                <div className={styles.price}>{nft?.price / 1e18} MATIC</div>
                            </div>
                        </div>
                    </div>
                )}


                {(isOnSale && isMyItem) ? (<></>) : (
                    <div className={styles.foot}>
                        <button
                            className={cn("button", styles.button)}
                            onClick={() => onButtonClick()}
                        >
                            {buttonText()}
                        </button>
                    </div>
                )}
                {/*<div className={styles.text}>*/}
                {/*    Service fee <span className={styles.percent}>1.5%</span>{" "}*/}
                {/*    <span>2.563 ETH</span> <span>$4,540.62</span>*/}
                {/*</div>*/}
            </div>

            <Modal
                visible={visibleModalPurchase}
                onClose={() => {
                    setVisibleModalPurchase(false)
                    window.location.reload();
                }}
            >
                {
                    successPurchase 
                        ? (<SuccessfullyPurchased nft={nft}/>)
                        : (<Checkout nft={nft} onSuccessPurchase={() => setSuccessPurchase(true)}/>)
                }
            </Modal>
            <Modal
                visible={visibleModalPutSale}
                onClose={() => setVisibleModalPutSale(false)}
            >
                <PutSale nft={nft}/>
            </Modal>
        </>
    );
};

export default Control;
