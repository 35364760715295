import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./AddItems.module.sass";
import Loader from "../../../components/Loader";
import Items from "../Items";
import {useOnSaleItems} from "../../../hooks/useOnSaleItems";
import {useToastContext} from "../../../providers/ToastProvider";
import ItemService from "../../../services/ItemService";
import {useCollectedItems} from "../../../hooks/useCollectedItems";
import {useCollectionItems} from "../../../hooks/useCollectionItems";
import {useTranslation} from "react-i18next";

const AddItems = ({className, collectionId}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [items, setItems] = useState([])
    const { onSaleItems } = useOnSaleItems();
    const { collectedItems } = useCollectedItems();
    const {collectionItems} = useCollectionItems(collectionId);
    const [isAdding, setIsAdding] = useState(false);
    const toast = useToastContext();
    const {t} = useTranslation('AddItemsCollection');

    useEffect(() => {
        if (!onSaleItems || !collectedItems) {
            return;
        }

        const items = [...onSaleItems, ...collectedItems].filter((item) => !collectionItems.some(collectionItem => item.token_address === collectionItem.contractAddress && item.token_id === collectionItem.tokenId.toString()))
        
        setItems(items);
        setIsLoading(false)
    }, [onSaleItems, collectedItems, collectionItems])

    const add = async (items) => {
        setIsAdding(true);

        try {
            await ItemService.create(items, collectionId);
            
            toast.success('Items added to collection successfully');

            window.location.reload();
        } catch (e) {
            toast.error('Failed to add items to collection');
        }

        setIsAdding(false);
    }

    return (
        <>
            <div className={cn("h4", styles.title)}>{t('title')}</div>
            <div className={cn(className, styles.sale)}>
                <Items
                    class={styles.items}
                    items={items}
                    isLoading={isLoading}
                    selectable={true}
                    onChange={(items) => setSelectedItems(items)}
                />
                {(!isLoading && items.length > 0) && <div className={styles.btns}>
                    <button
                        className={cn("button", styles.button)}
                        onClick={() => add(selectedItems)}
                        disabled={isAdding}
                    >
                        {t('button')} {(selectedItems.length)}
                        {isAdding && <Loader color={'white'} className={styles.addLoader}/>}
                    </button>
                </div>}
            </div>
        </>
    );
};

export default AddItems;
