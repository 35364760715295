import React, {useState} from "react";
import cn from "classnames";
import Identicon from "react-identicons";
import { useTranslation } from "react-i18next";
import styles from "./User.module.sass";
import Icon from "../../../components/Icon";
import Report from "../../../components/Report";
import Modal from "../../../components/Modal";
import { shortenAddress } from "../../../utils/utils";
import { useMeContext } from "../../../providers/MeProvider";
import { useDate } from "../../../hooks/useDate";

const UserAvatar = () => {
    const { me: { avatar, wallets } } = useMeContext();

    if (!avatar) {
        return (
            <div className={styles.avatar}>
                <Identicon string={wallets?.ethAddress} size={156} className={styles.identicon} />
            </div>
        );
    }

    return (
        <div className={styles.avatar}>
            <img src={avatar} alt={wallets?.ethAddress} />
        </div>
    );
}

const UserName = () => {
    const { me: { name } } = useMeContext();

    if (!name) {
        return null;
    }

    return <div className={styles.name}>{name}</div>;
}

const UserContractAddress = () => {
    const { me: { wallets } } = useMeContext();

    if (!wallets?.ethAddress) {
        return null;
    }

    return (
        <div className={styles.code}>
            <div className={styles.number}>{shortenAddress(wallets.ethAddress)}</div>
            <button className={styles.copy}>
                <Icon name="copy" size="16"/>
            </button>
        </div>
    );
}

const UserDescription = () => {
    const { me: { description } } = useMeContext();

    if (!description) {
        return null;
    }

    return (
        <div className={styles.info}>
            {description}
        </div>
    );
};

const UserCustomUrl = () => {
    const { me: { customUrl } } = useMeContext();

    if (!customUrl) {
        return null;
    }

    return (
        <a
            className={styles.site}
            href={customUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon name="globe" size="16"/>
            <span>{customUrl}</span>
        </a>
    );
};

const UserSocialLinks = () => {
    const { me: { socialLinks: links } } = useMeContext();

    if (!links) {
        return null;
    }

    const keys = Object.keys(links).filter((key) => links[key]);

    if (!keys.length) {
        return null;
    }

    return (
        <div className={styles.socials}>
            {keys.map((key) => (
                <a
                    className={styles.social}
                    href={links[key]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={key}
                >
                    <Icon name={key} size="20" viewBox={key === 'telegram' ? '0 0 30 30' : null}/>
                </a>
            ))}
        </div>
    );
}

const UserSince = () => {
    const { me: { createdAt } } = useMeContext();
    const { t } = useTranslation('Profile');
    const { formatDate } = useDate();

    if (!createdAt) {
        return null;
    }

    return (
        <div className={styles.note}>
            {formatDate(new Date(createdAt), `'${t('User.note')}' MMM d, yyyy`)}
        </div>
    );
}

const User = ({ className }) => {
    const [visibleModalReport, setVisibleModalReport] = useState(false);

    return (
        <>
            <div className={cn(styles.user, className)}>
                <UserAvatar />
                <UserName />
                <UserContractAddress />
                <UserDescription />
                <UserCustomUrl />
                <UserSocialLinks />
                <UserSince />
            </div>
            <Modal
                visible={visibleModalReport}
                onClose={() => setVisibleModalReport(false)}
            >
                <Report/>
            </Modal>
        </>
    );
};

export default User;
