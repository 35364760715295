import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Checkout.module.sass";
import LoaderCircle from "../../../../components/LoaderCircle";
import {useMoralisDapp} from "../../../../providers/MoralisDappProvider";
import {useMoralis, useWeb3ExecuteFunction} from "react-moralis";
import {useNativeBalance} from "../../../../hooks/useNativeBalance";
import {useNFTMarketData} from "../../../../hooks/useNftMarketData";
import {Trans, useTranslation} from "react-i18next";

const Checkout = ({className, nft, onSuccessPurchase}) => {
    const {
        walletAddress,
        purchaseItemFunction,
        marketAddress,
        contractABI,
        marketItemsTable,
        chainId
    } = useMoralisDapp();
    const {Moralis} = useMoralis();
    const {balance} = useNativeBalance();
    const {NFTMarketData} = useNFTMarketData(nft.contractAddress, nft.tokenId);
    const contractProcessor = useWeb3ExecuteFunction();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const {t} = useTranslation('Item');

    useEffect(() => {
        if (!NFTMarketData) {
            return;
        }
    }, [NFTMarketData])

    useEffect(() => {
        const price = nft?.price / 1e18;

        setData([
            {
                title: price,
                value: 'MATIC',
            },
            {
                title: t('Checkout.yourBalance'),
                value: `${parseFloat(balance.formatted.toFixed(4))} MATIC`,
            },
            {
                title: t('Checkout.youWillPay'),
                value: `${price} MATIC`,
            },
        ])
    }, [nft, balance])

    const failPurchase = () => {
        //TODO: add fail purchase screen
    }

    const purchase = async () => {
        setLoading(true);
        const tokenDetails = nft.marketData;
        const itemID = tokenDetails.itemId;
        const tokenPrice = tokenDetails.price;
        const ops = {
            contractAddress: marketAddress,
            functionName: purchaseItemFunction,
            abi: contractABI,
            params: {
                nftContract: nft.token_address,
                itemId: itemID,
            },
            msgValue: tokenPrice,
        };

        await contractProcessor.fetch({
            params: ops,
            onSuccess: () => {
                updateSoldMarketItem();
                setLoading(false);
                onSuccessPurchase();
            },
            onError: (error) => {
                console.log(error)
                setLoading(false);
                failPurchase();
            },
        });
    }


    async function updateSoldMarketItem() {
        try {
            const id = NFTMarketData.objectId;
            const marketList = Moralis.Object.extend(marketItemsTable);
            const query = new Moralis.Query(marketList);
            await query.get(id).then((obj) => {
                obj.set("sold", true);
                obj.set("owner", walletAddress);
                obj.save();
            });
            await updateMetaData();
        } catch (e) {
            console.log(e)
        }
    }

    async function updateMetaData(){
        const options = {method: 'GET', headers: {Accept: 'application/json', 'X-API-Key': 'IyKAvsaTmcSc5nLjfED4duRFKfYmFZdzTcT1BTwWYbyrNcgvbUguHZhQVDLoZs3c'}};
        await fetch(`https://deep-index.moralis.io/api/v2/nft/${nft.contractAddress}/${nft.tokenId}/metadata/resync?chain=${chainId}&flag=uri&mode=async`, options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
    }

    return (
        <div className={cn(className, styles.checkout)}>
            {loading && <>
                <div className={cn("h4", styles.title)}>
                    {t('Checkout.loading.title')}
                </div>
                <div className={styles.line}>
                    <div className={styles.icon}>
                        <LoaderCircle className={styles.loader}/>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.subtitle}>
                            {t('Checkout.loading.subtitle')}
                        </div>
                        <div className={styles.text}>
                            {t('Checkout.loading.text')}
                        </div>
                    </div>
                </div>
            </>}
            {!loading && <>
                <div className={cn("h4", styles.title)}>
                    {t('Checkout.title')}
                </div>
                <div className={styles.info}>
                    <Trans
                        i18nKey="Checkout.info"
                        t={t}
                        values={{metadataName: nft?.metadata?.name, name: nft?.name}}
                        components={[<strong/>]}
                    />
                </div>
                <div className={styles.table}>
                    {data.map((x, index) => (
                        <div className={styles.row} key={index}>
                            <div className={styles.col}>{x.title}</div>
                            <div className={styles.col}>{x.value}</div>
                        </div>
                    ))}
                </div>
            </>}
            {!loading && (<div className={styles.btns}>
                <button onClick={() => purchase()} className={cn("button", styles.button)}>
                    {t('Checkout.continue')}
                </button>
                <button className={cn("button-stroke", styles.button)}>
                    {t('Checkout.cancel')}
                </button>
            </div>)}
        </div>
    );
};

export default Checkout;
