import { useEffect, useState } from "react"
import CollectionService from "../services/CollectionService";

export const useCollections = () => {
    const [collections, setCollections] = useState([]);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        const getCollections = async () => {
            setIsLoading(true);

            try {
                const { data } = await CollectionService.getMany();

                setCollections(data);
            } catch(_) {
                setCollections([]);
            }

            setIsLoading(false);
        }

        getCollections();
    }, []);

    return { collections, isLoading };
};
