import httpClient from "../utils/httpClient";

const CollectionRepository = {
  create(data) {
    return httpClient.post('/collections', data, {
      headers: {
        requireAuthorization: true
      }
    });
  },

  update(id, data) {
    return httpClient.patch(`/collections/${id}`, data, {
      headers: {
        requireAuthorization: true
      }
    });
  },

  getOne(query) {
    return httpClient.get(`/collections/${query}`);
  },

  getMany() {
    return httpClient.get('/collections');
  },

  delete(id) {
    return httpClient.delete(`/collections/${id}`);
  }
};

export default CollectionRepository;
