import cn from "classnames";
import { useField } from 'formik';
import styles from "./TextArea.module.sass";

const TextArea = ({ className, label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={cn(styles.field, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.wrap}>
        <textarea className={styles.textarea} {...field} {...props} />
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextArea;
