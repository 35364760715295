import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Options.module.sass";
import Icon from "../../../components/Icon";
import Actions from "../../../components/Actions";
import Share from "../../../components/Share";
import FavoriteItemService from "../../../services/FavoriteItemService";
import { useFavoriteItemsContext } from "../../../providers/FavoriteItemsProvider";

const Options = ({className, nft, isOnSale, isMyItem}) => {
    const [showOnFavorites, setShowOnFavorites] = useState(false);
    const {addFavoriteItem, deleteFavoriteItem} = useFavoriteItemsContext();

    useEffect(() => {
        const getFavorite = async () => {
            try {
                await FavoriteItemService.getOne(nft.token_address, nft.token_id);

                setShowOnFavorites(true);
            } catch (_) {
            }
        }

        getFavorite();
    }, [nft])
    
    return (
        <>
        <div className={cn(styles.options, className)}>
            <Share className={styles.share} nft={nft} />
            <button
                className={cn("button-circle-stroke", styles.button, styles.favorite)}
                onClick={() => {
                    if (showOnFavorites) {
                        deleteFavoriteItem(nft);
                     } else {
                        addFavoriteItem(nft);
                    }

                    setShowOnFavorites(!showOnFavorites);
                }}>
                {(showOnFavorites) ? (<Icon name="heart-fill" size="24"/>) : (<Icon name="heart" size="24"/>)}
            </button>
            {(isMyItem && !isOnSale) ? (<Actions className={styles.actions} nft={nft}/>) : (<></>)}
        </div>

        </>
    );
};

export default Options;
