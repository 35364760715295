import { createContext, useContext } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContext = createContext();

export const ToastProvider = (props) => {
    const { children } = props;

    return (
        <ToastContext.Provider value={toast}>
            {children}
            <ToastContainer />
        </ToastContext.Provider>
    );
}

export const useToastContext = () => {
    return useContext(ToastContext);
}