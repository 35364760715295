import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation('Home');

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <div className={styles.stage}>
              {t('Hero.stage')}
            </div>
            <h2 className={cn("h3", styles.title)}>
              {t('Hero.title')}
            </h2>
            <Link className={cn("button-stroke", styles.button)} to="/search">
              {t('Hero.button')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
