import React from "react";
import Hero from "./Hero";
import Collections from "./Collections";
import Description from "./Description";

const Home = () => {
    return (
        <>
            <Description/>
            <Collections/>
            <Hero/>
        </>
    );
};

export default Home;
