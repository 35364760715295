import React from "react";
import cn from "classnames";
import styles from "./Report.module.sass";
import TextArea from "../TextArea";
import { useTranslation } from "react-i18next";

const Report = ({ className }) => {
  const { t } = useTranslation('Report');

  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn("h4", styles.title)}>
        {t('title')}
      </div>
      <div className={styles.text}>
        {t('text')}
      </div>
      <TextArea
        className={styles.field}
        label={t('messageField.label')}
        name="message"
        placeholder={t('messageField.placeholder')}
        required="required"
      />
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}>
          {t('sendNow')}
        </button>
        <button className={cn("button-stroke", styles.button)}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default Report;
