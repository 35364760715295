import React, { useState } from "react";
import cn from "classnames";
import { Range, getTrackBackground } from "react-range";
import { useTranslation } from "react-i18next";
import { useDate } from "../../../hooks/useDate";
import styles from "./User.module.sass";
import Icon from "../../../components/Icon";
import Report from "../../../components/Report";
import Modal from "../../../components/Modal";

const SocialLinks = (props) => {
  const { links } = props;

  const keys = Object.keys(links).filter((key) => links[key]);

  if (!keys.length) {
    return null;
  }

  return (
    <div className={styles.socials}>
      {keys.map((key) => (
        <a
          className={styles.social}
          href={links[key]}
          target="_blank"
          rel="noopener noreferrer"
          key={key}
        >
          <Icon name={key} size="20" viewBox={key === 'telegram' ? '0 0 30 30' : null}/>
        </a>
      ))}
    </div>
  );
}

const User = (props) => {
  const { className, collection } = props;
  
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [values, setValues] = useState([5]);
  const { t } = useTranslation('Collection');
  const { formatDate } = useDate();

  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;

  return (
    <>
      <div className={cn(styles.user, className)}>
        <div className={styles.avatar}>
          <img src={collection.logo} alt="" />
        </div>
        <div className={styles.name}>{collection.name}</div>
        <div className={styles.createdBy}>{t('User.createdBy')} <span className={styles.username}>{ collection.user?.name }</span></div>
        <div className={styles.info}>
          {collection.description}
        </div>
        {collection.customUrl && <a
          className={styles.site}
          href={collection.customUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="globe" size="16" />
          <span className={styles.siteUrl}>{collection.customUrl}</span>
        </a>}
        {collection.socialLinks && <SocialLinks links={collection.socialLinks} />}
        {collection.createdAt && <div className={styles.note}>{formatDate(new Date(collection.createdAt), `'${t('User.createdAt')}' MMM d, yyyy`)}</div>}
      </div>
      {false && <div>
        <div className={styles.label}>Price range</div>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => setValues(values)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "27px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values,
                    colors: ["#3772FF", "#E6E8EC"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                backgroundColor: "#3772FF",
                border: "4px solid #FCFCFD",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-33px",
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontFamily: "Manrope",
                  padding: "4px 8px",
                  borderRadius: "8px",
                  backgroundColor: "#141416",
                }}
              >
                {values[0].toFixed(1)}
              </div>
            </div>
          )}
        />
        <div className={styles.scale}>
          <div className={styles.number}>0.01 ETH</div>
          <div className={styles.number}>10 ETH</div>
        </div>
      </div>}
      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report />
      </Modal>
    </>
  );
};

export default User;
