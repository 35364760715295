import httpClient from "../utils/httpClient";

const UserRepository = {
  getCollections() {
    return httpClient.get('/collections', {
      headers: {
        requireAuthorization: true
      }
    });
  },
};

export default UserRepository;
