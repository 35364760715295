import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Users from "./Users";
import Control from "./Control";
import Options from "./Options";
import Details from "./Details";
import {useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import {useMoralisDapp} from "../../providers/MoralisDappProvider";
import FadeInOut from "../../components/Animation/FadeInOut";
import {shortenAddress} from "../../utils/utils";
import {useNftData} from "../../hooks/useNftData";
import { useTranslation } from "react-i18next";

const Item = () => {
    const {walletAddress} = useMoralisDapp();
    const {contractAddress, itemId} = useParams();
    const {nft, isLoading} = useNftData(contractAddress, itemId);
    const [isOnSale, setIsOnSale] = useState(false);
    const [isMyItem, setIsMyItem] = useState(false);
    const { t } = useTranslation('Item');

    useEffect(() => {
        if (nft?.marketData) {
            const onSale = nft.marketData.sold === false;

            setIsOnSale(onSale);

            if (onSale) {
                setIsMyItem(nft.marketData.seller === walletAddress);
            } else {
                setIsMyItem(nft?.owner_of === walletAddress);
            }
        } else {
            setIsOnSale(false);
            setIsMyItem(nft?.owner_of === walletAddress);
        }
    }, [nft, walletAddress]);

    if (isLoading) {
        return (
            <>
                <div className={cn("section", styles.section)}>
                    <Loader className={styles.loader}/>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={cn("section", styles.section)}>
                <FadeInOut show={!isLoading} duration={1000}>
                    <div className={cn("container", styles.container)}>
                        <div className={styles.bg}>
                            <div className={styles.preview}>
                                <div className={styles.categories}>
                                    {[{
                                        category: 'black',
                                        content: 'art',
                                    }].map((x, index) => (
                                        <div
                                            className={cn(
                                                { 'status-black': x.category === 'black' },
                                                { 'status-purple': x.category === 'purple' },
                                                styles.category
                                            )}
                                            key={index}
                                        >
                                            {x.content}
                                        </div>
                                    ))}
                                </div>
                                <img src={nft?.image} alt="Item" />
                            </div>
                            <Options className={styles.options} nft={nft} isOnSale={isOnSale} isMyItem={isMyItem}/>
                        </div>
                        <div className={styles.summary}>
                            <h1 className={cn("h3", styles.title)}>{nft?.metadata?.name ?? " "}</h1>
                            <div className={styles.info}>
                                {nft?.metadata?.description ?? " "}
                            </div>
                            <Users className={styles.users} items={[
                                {
                                    name: isOnSale ? nft?.marketData.seller : nft?.owner_of,
                                    position: (isOnSale ? t('Users.seller') : `${t('Users.owner') + (isMyItem ? ` (${t('Users.you')})` : '')}`),
                                },
                            ]}/>
                            <Details 
                                className={styles.details} 
                                items={[
                                    {
                                        label: t('Details.contractAddress'),
                                        data: shortenAddress(contractAddress),
                                        isMark: true,
                                        link: `https://polygonscan.com/address/${contractAddress}`
                                    },
                                    {
                                        label: t('Details.tokenID'),
                                        data: itemId,
                                        isMark: true
                                    },
                                    {
                                        label: t('Details.tokenStandard'),
                                        data: nft?.contract_type,
                                        isMark: false
                                    },
                                    {
                                        label: t('Details.blockchain'),
                                        data: "Polygon",
                                        isMark: false
                                    },
                                ]}
                            />

                            {
                                (isOnSale === false && isMyItem === false) 
                                    ? (<></>) 
                                    : (
                                        <Control className={styles.control} nft={nft} isOnSale={isOnSale} isMyItem={isMyItem} />
                                    )
                            }
                        </div>
                    </div>
                </FadeInOut>
            </div>
        </>
    );
};

export default Item;
