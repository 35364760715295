import {useEffect, useState} from "react";
import {useMoralisWeb3Api, useMoralisWeb3ApiCall} from "react-moralis";
import {useIPFS} from "./useIPFS";
import {useMoralisDapp} from "../providers/MoralisDappProvider";

export const useCollectedItems = (options) => {
    const {account} = useMoralisWeb3Api();
    const {chainId, walletAddress} = useMoralisDapp();
    const {resolveLink} = useIPFS();
    const [collectedItems, setCollectedItems] = useState([]);
    const {
        fetch: getCollectedItems,
        data,
        error,
        isLoading,
    } = useMoralisWeb3ApiCall(account.getNFTs, {chain: chainId, address: walletAddress, ...options});
    const [fetchSuccess, setFetchSuccess] = useState(true);

    useEffect(() => {
        if (!data?.result?.length) {
            return;
        }

        const getItems = async () => {
            const items = data.result;

            setFetchSuccess(true);

            for (let item of items) {
                if (item?.metadata) {
                    try {
                        item.metadata = JSON.parse(item.metadata);
                    } catch (e) {
                        console.log(e);
                    }

                    item.image = resolveLink(item.metadata?.image);
                } else if (item?.token_uri) {
                    try {
                        const response = await fetch(item.token_uri);
                        const decodedResponse = await response.json();
                        
                        item.image = resolveLink(decodedResponse.image);
                    } catch (error) {
                        setFetchSuccess(false);
                    }
                }
            }
            setCollectedItems(items);
        };

        getItems();
    }, [data]);

    return {getCollectedItems, collectedItems, fetchSuccess, error, isLoading};
};
