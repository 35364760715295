import { useEffect, useState } from "react"

import CategoryService from "../services/CategoryService";

export const useCategories = () => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        const getCategories = async () => {
            setIsLoading(true);

            try {
                const { data } = await CategoryService.getMany();

                setCategories(data);
            } catch(_) {
                setCategories([]);
            }

            setIsLoading(false);
        }

        getCategories();
    }, []);

    return { categories, isLoading };
};
