import React from "react";
import cn from "classnames";
import styles from "./RemoveSale.module.sass";
import { useTranslation } from "react-i18next";

const RemoveSale = ({ className }) => {
  const { t } = useTranslation('RemoveSale');

  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn("h4", styles.title)}>
        {t('title')}
      </div>
      <div className={styles.text}>
        {t('text')}
      </div>
      <div className={styles.btns}>
        <button className={cn("button", styles.button)}>
          {t('removeNow')}
        </button>
        <button className={cn("button-stroke", styles.button)}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default RemoveSale;
