import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Range, getTrackBackground } from "react-range";
import { useTranslation } from "react-i18next";
import Icon from "../../components/Icon";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import styles from "./Search01.module.sass";
import { useCategoriesContext } from "../../providers/CategoriesProvider";
import { useItemsSearch } from "../../hooks/useItemsSearch";
import { useQuery } from "../../hooks/useQuery";
import { EN_LANGUAGE_CODE } from "../../constants/i18n";

const STEP = 0.1;
const MIN = 0.01;
const MAX = 10;

const dateOptionsInitial = [
    {
        label: 'dateFilter.options.newest',
        value: 'newest', 
    },
    {
        label: 'dateFilter.options.oldest',
        value: 'oldest'
    }
];

const likesOptionsInitial = [
    {
        label: 'likesFilter.options.mostLiked', 
        value: 'mostLiked'
    },
    {
        label: 'likesFilter.options.leastLiked',
        value: 'leastLiked'
    }
];

const convertPriceToMatic = (price) => {
    return parseFloat(price) / 1e18;
};

const filterByCategory = (filter, items) => {
    if (!filter) {
        return items;
    }

    return items.filter((item) => item.collectionObj.category === filter);
}

const sortByDate = (filter, items) => {
    const isSortByNewest = filter.value === 'newest';

    if (isSortByNewest) {
        return [...items].sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
    } else {
        return [...items].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    }
};

const sortByLikes = (filter, items) => {
    const isSortByMostLiked = filter.value === 'mostLiked';

    if (isSortByMostLiked) {
        return [...items].sort((a, b) => b.likesCount - a.likesCount);
    } else {
        return [...items].sort((a, b) => a.likesCount - b.likesCount);
    }
};

const filterByPrice = (filter, items) => {
    return items.filter((item) => convertPriceToMatic(item.price) <= filter[0]);
};

const SearchResult = (props) => {
    const { result } = props;
    const { t } = useTranslation('Search');

    if (result === null) {
        return (
            <Loader className={styles.listLoader} />
        );
    }

    if (result.length < 1) {
        return (
            <div className={styles.listEmpty}>
                {t('notFound')}
            </div>
        );
    }

    return (
        <>
            {result.map((x, index) => (
                <Card className={styles.card} item={x} key={index}/>
            ))}
        </>
    );
}

const Search = () => {
    const { t, ready,i18n } = useTranslation('Search');

    const { search } = useQuery();

    const [searchQuery, setSearchQuery] = useState('');

    const [dateOptions, setDateOptions] = useState(dateOptionsInitial);
    const [date, setDate] = useState(dateOptions[0]);

    const [likesOptions, setLikesOptions] = useState(likesOptionsInitial);
    const [likes, setLikes] = useState(likesOptions[0]);

    const [values, setValues] = useState([5]);

    const [activeCategory, setActiveCategory] = useState('');
    const { categories } = useCategoriesContext();

    const { itemsSearchResult, setItemsSearchQuery } = useItemsSearch();

    const filterItems = (items) => {
        if (!items) {
            return items;
        }

        let filteredItems = filterByCategory(activeCategory, items);

        filteredItems = filterByPrice(values, filteredItems);
        filteredItems = sortByLikes(likes, filteredItems);
        filteredItems = sortByDate(date, filteredItems);

        return filteredItems;
    };

    const resetFilter = () => {
        setActiveCategory('');
        setDate(dateOptions[0]);
        setValues([5]);
        setLikes(likesOptions[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setItemsSearchQuery(searchQuery);
    };

    const handleResetFilter = () => {
        resetFilter();
    };

    useEffect(() => {
        setSearchQuery(search || '');
        setItemsSearchQuery(search || '');
    }, [search]);

    useEffect(() => {
        if (!ready) {
            return;
        }

        setDateOptions(dateOptionsInitial.map((option) => ({ ...option, ...{ label: t(option.label) }})));
        setLikesOptions(likesOptionsInitial.map((option) => ({ ...option, ...{ label: t(option.label) }})));
    }, [ready])

    useEffect(() => {
        setDate(dateOptions[0]);
        setLikes(likesOptions[0]);
    }, [likesOptions, dateOptions]);

    return (
        <div className={cn("section-pt80", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.top}>
                    <div className={styles.title}>{t('title')}</div>
                    <form
                        className={styles.search}
                        onSubmit={handleSubmit}
                    >
                        <input
                            className={styles.input}
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            name="search"
                            placeholder={t('searchField.placeholder')}
                        />
                        <button className={styles.result}>
                            <Icon name="search" size="16"/>
                        </button>
                    </form>
                </div>
                <div className={styles.sorting}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            className={styles.dropdown}
                            value={date}
                            setValue={setDate}
                            options={dateOptions}
                        />
                    </div>
                    <div className={styles.nav}>
                        {[...[{ _id: '', name: t('All') }], ...categories].map((category, index) => (
                            <button
                                className={cn(styles.link, {
                                    [styles.active]: category._id === activeCategory,
                                })}
                                onClick={() => setActiveCategory(category._id)}
                                key={index}
                            >
                                {i18n.language === EN_LANGUAGE_CODE ? category.name : category.nameRu}
                            </button>
                        ))}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.filters}>
                        <div className={styles.range}>
                            <div className={styles.label}>{t('priceFilter.label')}</div>
                            <Range
                                values={values}
                                step={STEP}
                                min={MIN}
                                max={MAX}
                                onChange={(values) => setValues(values)}
                                renderTrack={({props, children}) => (
                                    <div
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                            ...props.style,
                                            height: "36px",
                                            display: "flex",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            ref={props.ref}
                                            style={{
                                                height: "8px",
                                                width: "100%",
                                                borderRadius: "4px",
                                                background: getTrackBackground({
                                                    values,
                                                    colors: ["#3772FF", "#E6E8EC"],
                                                    min: MIN,
                                                    max: MAX,
                                                }),
                                                alignSelf: "center",
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                )}
                                renderThumb={({props, isDragged}) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: "24px",
                                            width: "24px",
                                            borderRadius: "50%",
                                            backgroundColor: "#3772FF",
                                            border: "4px solid #FCFCFD",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "-33px",
                                                color: "#fff",
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                fontFamily: "Manrope",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                backgroundColor: "#141416",
                                            }}
                                        >
                                            {values[0].toFixed(1)}
                                        </div>
                                    </div>
                                )}
                            />
                            <div className={styles.scale}>
                                <div className={styles.number}>0.01 MATIC</div>
                                <div className={styles.number}>10 MATIC</div>
                            </div>
                        </div>
                        <div className={styles.group}>
                            <div className={styles.item}>
                                <div className={styles.label}>{t('likesFilter.label')}</div>
                                <Dropdown
                                    className={styles.dropdown}
                                    value={likes}
                                    setValue={setLikes}
                                    options={likesOptions}
                                />
                            </div>
                        </div>
                        <div className={styles.reset} onClick={() => handleResetFilter()}>
                            <Icon name="close-circle-fill" size="24"/>
                            <span>{t('resetFilter')}</span>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.list}>
                            <SearchResult result={filterItems(itemsSearchResult)} />
                        </div>
                        {/* <div className={styles.btns}>
                            <button className={cn("button-stroke", styles.button)}>
                                <span>{t('loadMore')}</span>
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;
