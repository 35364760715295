import { deleteAccessToken, deleteRefreshToken, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from "../utils/storage";

import AuthRepository from "../repositories/AuthRepository";

function prepareUpdateMeData(data) {
  const formData = new FormData();

  for (const key in data) {
    let value = data[key];

    if (typeof value === 'object' && !(value instanceof File)) {
      value = JSON.stringify(value);
    }

    if (value === undefined) {
      continue;
    }

    formData.append(key, value);
  }

  return formData;
}

const AuthService = {
  async login(username, sessionToken) {
    try {
      const { data: { accessToken, refreshToken } } = await AuthRepository.login({
        username,
        sessionToken,
      });
  
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    } catch (error) {
      throw error;
    }
  },

  async logout() {
    const refreshToken = getRefreshToken();

    try {
      await AuthRepository.logout({
        refreshToken: refreshToken,
      });

      deleteAccessToken();
      deleteRefreshToken();
    } catch(error) {
      throw error;
    }
  },

  async getMe(moralisUser) {
    const accessToken = getAccessToken();

    if (!accessToken) {
      await AuthService.login(moralisUser.getUsername(), moralisUser.getSessionToken());
    }

    const { data } = await AuthRepository.getMe();

    return data;
  },

  updateMe(data) {
    return AuthRepository.updateMe(prepareUpdateMeData(data));
  }
}

export default AuthService;