import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import cn from "classnames";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import CollectionService from "../../services/CollectionService";
import { useToastContext } from "../../providers/ToastProvider";
import styles from "./CollectionEdit.module.sass";
import Category from "./Category";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const CollectionLogo = (props) => {
  const { onChange, value } = props;

  const { t } = useTranslation('CollectionEdit');
  const [url, setUrl] = useState('');
  const uploadRef = useRef(null);

  const handleUpload = (e) => {
    const file = e.target.files[0];

    setUrl(URL.createObjectURL(file));

    onChange(file);
  };

  useEffect(() => {
    setUrl(value);
  }, [value]);

  return (
    <div className={styles.user}>
      <div className={styles.avatar}>
        <img src={url} alt="" />
      </div>
      <div className={styles.details}>
        <div className={styles.stage}>{t('logo.stage')}</div>
        <div className={styles.text}>
          {t('logo.text')}
        </div>
        <div className={styles.file}>
          <button
            type="button"
            className={cn(
              "button-stroke button-small",
              styles.button
            )}
            onClick={() => uploadRef.current.click()}
          >
            {t('logo.button')}
          </button>
          <input className={styles.load} type="file" ref={uploadRef} onChange={(e) => handleUpload(e)} />
        </div>
      </div>
    </div>
  );
}

const CollectionBanner = (props) => {
  const { onChange, value } = props;

  const { t } = useTranslation('CollectionEdit');
  const [url, setUrl] = useState('');
  const uploadRef = useRef(null);

  const handleUpload = (e) => {
    const file = e.target.files[0];

    setUrl(URL.createObjectURL(file));

    onChange(file);
  };

  useEffect(() => {
    setUrl(value);
  }, [value]);

  return (
    <div className={styles.cover}>
      <div className={styles.coverTitle}>
        {t('cover.title')}
      </div>
      <div className={styles.coverText}>
        {t('cover.text')}
      </div>
      <div
        className={styles.coverFile}
        onClick={() => uploadRef.current.click()}
        style={{
          backgroundImage: url ? `url(${url})` : 'none'
        }}
      >
        <input
          className={styles.coverFileLoad}
          type="file"
          ref={uploadRef}
          onChange={(e) => handleUpload(e)}
        />
        <div className={styles.coverFileIcon}>
          <Icon name="upload-file" size="24" />
        </div>
        <div className={styles.coverFileFormat}>
          PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
        </div>
      </div>
    </div>
  );
}

const collectionDefault = {
  logo: undefined,
  banner: undefined,
  name: '',
  slug: '',
  description: '',
  category: '',
  customUrl: '',
  socialLinks: {
    twitter: '',
    instagram: '',
    telegram: '',
  }
};

const serializeCollectionData = (data, keys) => {
  const serializedData = {};

  for (const key of keys) {
    serializedData[key] = data[key];
  }

  return serializedData;
};

const CollectionDeleteButton = (props) => {
  const { collectionId } = props;

  const { t } = useTranslation('CollectionEdit');
  const toast = useToastContext();
  const history = useHistory()
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await CollectionService.delete(collectionId);

      toast.success(t('deleteButton.toast.success'));

      history.push('/profile', { activeIndex: 3 });
    } catch(_) {
      toast.error(t('deleteButton.toast.error'))
    }

    setIsDeleting(false);
  }

  return (
    <button
      type="button"
      className={styles.delete}
      onClick={() => handleDelete()}
      disabled={isDeleting}
    >
      <Icon name="circle-close" size="24" />
      {`${isDeleting ? t('deleteButton.loading') : t('deleteButton.text')}`}
    </button>
  );
};

const CollectionEdit = () => {
  const { t } = useTranslation('CollectionEdit');
  const { query } = useParams();
  const [collection, setCollection] = useState(collectionDefault);
  const toast = useToastContext();

  useEffect(() => {
    if (!query) {
      return;
    }

    const initCollection = async () => {
      try {
        const { data } = await CollectionService.getOne(query);

        setCollection(data);
      } catch(_) {}
    }

    initCollection();
  }, [query]);

  return (
    <div className={styles.page}>
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>{t('title')}</h1>
            <CollectionDeleteButton collectionId={collection._id} />
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={serializeCollectionData(collection, Object.keys(collectionDefault))}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().required(t('nameField.errors.required')),
              slug: Yup.string().trim().required(t('slugField.errors.required')),
              description: Yup.string().trim(),
              category: Yup.string().required(t('categoryField.errors.required')),
              customUrl: Yup.string().trim().url(t('customUrlField.errors.url')),
              socialLinks: Yup.object({
                twitter: Yup.string().trim().url(t('twitterField.errors.url')),
                telegram: Yup.string().trim().url(t('telegramField.errors.url')),
                instagram: Yup.string().trim().url(t('instagramField.errors.url')),
              }),
            })}
            onSubmit={async (values) => {
              try {
                await CollectionService.update(collection._id, values);

                toast.success(t('toast.success'));
              } catch(_) {
                toast.error(t('toast.error'));
              }
            }}
          >
            {props => {
              const { setFieldValue, getFieldProps, handleSubmit, submitCount, isSubmitting, isValid } = props;

              const isSubmitDisabled = isSubmitting || (!isValid && submitCount > 1);
              
              const categoryFieldProps = getFieldProps('category');

              return (
                <Form onSubmit={handleSubmit}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <CollectionLogo onChange={(file) => setFieldValue('logo', file)} value={collection.logo} />
                      <CollectionBanner onChange={(file) => setFieldValue('banner', file)} value={collection.banner} />
                    </div>
                    <div className={styles.col}>
                      <div className={styles.list}>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('mainInfo')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label={t('nameField.label')}
                              name="name"
                              type="text"
                              placeholder={t('nameField.placeholder')}
                            />
                            <TextInput
                              className={styles.field}
                              label={t('slugField.label')}
                              name="slug"
                              type="text"
                              placeholder="nft.kvell.group/collections/[URL Slug]"
                            />
                            <TextArea
                              className={styles.field}
                              label={t('descriptionField.label')}
                              name="description"
                              placeholder={t('descriptionField.placeholder')}
                            />
                            <div className={styles.label}>{t('categoryField.label')}</div>
                            <Category selected={categoryFieldProps.value} onSelect={(id) => setFieldValue('category', id)} />
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('social')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label={t('customUrlField.label')}
                              name="customUrl"
                              type="text"
                              placeholder={t('customUrlField.placeholder')}
                            />
                            <TextInput
                              className={styles.field}
                              label="twitter"
                              name="socialLinks.twitter"
                              type="text"
                              placeholder="https://twitter.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="instagram"
                              name="socialLinks.instagram"
                              type="text"
                              placeholder="https://instagram.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="telegram"
                              name="socialLinks.telegram"
                              type="text"
                              placeholder="https://t.me"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.blockchain}>
                        <div className={styles.blockchainTitle}>
                          {t('blockchain.title')}
                        </div>
                        <div className={styles.blockchainText}>
                          {t('blockchain.text')}
                        </div>
                        <div className={styles.blockchainBtns}>
                          <div className={styles.blockchainBtnWrap}>
                            <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnActive]: true })}>
                              <img
                                className={styles.blockchainBtnIcon}
                                src="/icons/polygon-token.svg"
                                alt="Polygon"
                              />
                              <span className={styles.blockchainBtnText}>Polygon</span>
                            </button>
                          </div>
                          <div className={styles.blockchainBtnWrap}>
                            <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnDisabled]: true })}>
                              <img
                                className={styles.blockchainBtnIcon}
                                src="/icons/ethereum-eth.svg"
                                alt="Etherium"
                              />
                              <span className={styles.blockchainBtnText}>Etherium</span>
                            </button>
                            <span className={styles.blockchainBtnNote}>{t('blockchain.note')}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.btns}>
                        <button className={cn("button", styles.button)} type="submit" disabled={isSubmitDisabled}>
                          {t('submit')}
                        </button>
                        {isSubmitting && <Loader className={styles.submitLoader}/>}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CollectionEdit;
