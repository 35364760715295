import { createContext, useContext, useEffect, useState } from "react";
import { useMeContext } from "./MeProvider";
import FavoriteItemService from "../services/FavoriteItemService";
import {useToastContext} from "./ToastProvider";
import {useTranslation} from "react-i18next";

export const FavoriteItemsContext = createContext({});

export const FavoriteItemsProvider = (props) => {
    const { children } = props;

    const { me } = useMeContext();
    const toast = useToastContext();
    const { t } = useTranslation('common');

    const [favoriteItems, setFavoriteItems] = useState([]);

    useEffect(() => {
        if (!me?.username) {
            return;
        }

        const getFavoriteItems = async () => {
            try {
                const { data } = await FavoriteItemService.getMany();

                setFavoriteItems(data);
            } catch (_) {
            }
        };

        getFavoriteItems();
    }, [me]);

    const deleteFavoriteItem = async (item) => {
        const { token_address, token_id } = item;

        try {
            await FavoriteItemService.delete(token_address, token_id);

            setFavoriteItems(favoriteItems.filter((element) =>
                element.contractAddress !== token_address && token_id !== element.tokenId
            ));

            toast.success(t('removedFavorites'));
        } catch (_) {}
    }

    const addFavoriteItem = async (item) => {
        const { token_address, token_id } = item;

        try {
            await FavoriteItemService.create(token_address, token_id);

            setFavoriteItems([
                ...[{ contractAddress: token_address, tokenId: token_id }],
                ...favoriteItems,
            ]);

            toast.success(t('addedFavorites'));
        } catch (_) {}
    }

    return (
        <FavoriteItemsContext.Provider value={{ favoriteItems, addFavoriteItem, deleteFavoriteItem }}>
            {children}
        </FavoriteItemsContext.Provider>
    );
}

export const useFavoriteItemsContext = () => {
    return useContext(FavoriteItemsContext);
}
