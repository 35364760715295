import { useEffect, useState } from "react";

import ItemService from "../services/ItemService";

export const useItemsSearch = () => {
    const [itemsSearchResult, setItemsSearchResult] = useState(null);
    const [itemsSearchQuery, setItemsSearchQuery] = useState('')
    const [isItemsSearchLoading, setIsItemsSearchLoading] = useState(false);

    useEffect(() => {
        const searchItems = async () => {
            setIsItemsSearchLoading(true);

            try {
                const { data } = await ItemService.getBySearchQuery(itemsSearchQuery);

                setItemsSearchResult(data);
            } catch (_) {
                setItemsSearchResult([]);
            }

            setIsItemsSearchLoading(false);
        };

        searchItems();
    }, [itemsSearchQuery]);

    return { itemsSearchResult, setItemsSearchQuery, isItemsSearchLoading };
};
