import { useEffect, useState } from "react"
import FavoriteItemService from "../services/FavoriteItemService";
import {Web3API} from "moralis";
import {useMoralisDapp} from "../providers/MoralisDappProvider";
import {useIPFS} from "./useIPFS";

export const useFavoriteItems = () => {
  const {chainId} = useMoralisDapp();
  const {resolveLink} = useIPFS();
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);

      try {
        const { data } = await FavoriteItemService.getMany();

        const items = await Promise.all(data.map(async (item) => {
          const data = await Web3API.token.getTokenIdMetadata({
            address: item.contractAddress,
            token_id: item.tokenId,
            chain: chainId
          });
          const decodedData = JSON.parse(JSON.stringify(data));

          decodedData.price = item.price;

          if (decodedData?.metadata) {
            decodedData.metadata = JSON.parse(decodedData.metadata);
            decodedData.image = resolveLink(decodedData.metadata?.image);
          } else if (decodedData?.token_uri) {
            try {
              const response = await fetch(decodedData.token_uri);
              const decodedResponse = await response.json();

              decodedData.image = resolveLink(decodedResponse.image);
            } catch (_) {}
          }

          return decodedData;
        }));
        
        setFavoriteItems(items);
      } catch(_) {
        setFavoriteItems([]);
      }

      setIsLoading(false);
    }

    getItems();
  }, []);

  return { favoriteItems, isLoading };
};
