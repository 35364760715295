import axios from 'axios';
import mem from 'mem/dist';

import { deleteAccessToken, deleteRefreshToken, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './storage';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const refreshToken = async () => {
  try {
    const response = await httpClient.post('/auth/refresh', {
      refreshToken: getRefreshToken(),
    });

    const { accessToken, refreshToken } = response.data;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    return accessToken;
  } catch (_) {
    deleteAccessToken();
    deleteRefreshToken();
  }
};

const maxAge = 10000;

const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});

httpClient.interceptors.request.use((config) => {
  if (config.headers.requireAuthorization) {
    const accessToken = getAccessToken();

    delete config.headers.requireAuthorization;

    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const accessToken = await memoizedRefreshToken();

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return axios(config);
    }

    return Promise.reject(error);
  }
);

export default httpClient;
