import httpClient from "../utils/httpClient";

const FavoriteItemRepository = {
    create(data) {
        return httpClient.post('/favorites', data, {
            headers: {
                requireAuthorization: true
            }
        });
    },

    getMany() {
        return httpClient.get(`/favorites`,{
            headers: {
                requireAuthorization: true
            }
        });
    },

    getOne(contractAddress, tokenId) {
        return httpClient.get(`/favorites/${contractAddress}/${tokenId}`, {
            headers: {
                requireAuthorization: true
            }
        });
    },

    delete(contractAddress, tokenId) {
        return httpClient.delete(`/favorites/${contractAddress}/${tokenId}`, {
            headers: {
                requireAuthorization: true
            }
        })
    }
};

export default FavoriteItemRepository;
