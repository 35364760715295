import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Items.module.sass";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";

const Items = (props) => {
  const { className, items, isLoading = false, selectable = false, onChange = (items) => {} } = props;
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    onChange(selectedItems);
  }, [onChange, selectedItems]);

  const handleClick = (item) => {
    if (selectedItems.includes(item)) {      
      setSelectedItems(selectedItems.filter((prevItem) => prevItem !== item));
    } else {
      setSelectedItems([...selectedItems, ...[item]]);
    }
  }

  if (isLoading) {
    return (
      <Loader className={styles.loader}/>
    );
  }

  return (
    <div className={cn(styles.items, className)}>
      <div className={styles.list}>
        {items.map((item, index) => (
          <Card
            className={styles.card}
            item={item}
            key={index}
            selectable={selectable}
            selected={selectedItems.includes(item)}
            onClick={() => handleClick(item)}
          />
        ))}
      </div>
    </div>
  );
};

export default Items;
