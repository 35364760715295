
// The character limit on ENS names, nicknames and addresses before we truncate
export const TRUNCATED_NAME_CHAR_LIMIT = 11;

// The number of characters to slice from the beginning of an address for truncated format:
// `${TRUNCATED_ADDRESS_START_CHARS}...${TRUNCATED_ADDRESS_END_CHARS}`
export const TRUNCATED_ADDRESS_START_CHARS = 18;

// The number of characters to slice from the end of an address for truncated format:
// `${TRUNCATED_ADDRESS_START_CHARS}...${TRUNCATED_ADDRESS_END_CHARS}`
export const TRUNCATED_ADDRESS_END_CHARS = 4;

export function shortenAddress(address = '') {
    if (address.length < TRUNCATED_NAME_CHAR_LIMIT) {
        return address;
    }

    return `${address.slice(0, TRUNCATED_ADDRESS_START_CHARS)}...${address.slice(
        -TRUNCATED_ADDRESS_END_CHARS,
    )}`;
}

export function convertedPrice(price){
    return price / ("1e" + 18) + " MATIC";
}


export function openInNewTab(href) {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href,
    }).click();
}

export function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export const urlExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/