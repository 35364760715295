import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styles from "./Description.module.sass";
import Image from "../../../components/Image";
import {useMoralis} from "react-moralis";
import CreateMenu from "../../../components/Header/CreateMenu";
import User from "../../../components/Header/User";
import {useHistory} from "react-router";
import {timeout} from "../../../utils/utils";

const Description = () => {
  const { t } = useTranslation('Home');
  const {authenticate, isAuthenticated} = useMoralis();
  const history = useHistory();

  const handleConnectWallet = (e) => {
    e.preventDefault();
    authenticate().then((value => {
      timeout(2000).then(()=>{
        history.push('/items/create');
      })
    }));
  }
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={styles.stage}>{t('Description.stage')}</div>
          <h1 className={cn("h1", styles.title)}>
            {t('Description.title', { nbsp: '\u00A0' })}
          </h1>
          <div className={styles.text}>
            <Trans i18nKey="Description.text" t={t} components={[<br/>]} />
          </div>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/collections">
              {t('Description.explore')}
            </Link>
            {
              isAuthenticated
                  ?
                  (
                      <>
                        <Link className={cn("button-stroke", styles.button)} to="/items/create">
                          {t('Description.create')}
                        </Link>
                      </>
                  )
                  :
                  (
                      <>
                        <Link
                            to="#"
                            className={cn("button-stroke", styles.button)}
                            onClick={handleConnectWallet}
                        >
                          {t('Description.create')}
                        </Link>
                      </>
                  )
            }
          </div>
        </div>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/cubes@2x.png 2x"
              srcSetDark="/images/content/cubes-dark@2x.png 2x"
              src="/images/content/cubes.png"
              srcDark="/images/content/cubes-dark.png"
              alt="Cubes"
            />
          </div>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/cube@2x.png 2x"
              srcSetDark="/images/content/cube-dark@2x.png 2x"
              src="/images/content/cube.png"
              srcDark="/images/content/cube-dark.png"
              alt="Cube"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
