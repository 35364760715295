import httpClient from "../utils/httpClient";

const AuthRepository = {
  login(data) {
    return httpClient.post('/auth/login', data);
  },

  logout(data) {
    return httpClient.post('/auth/logout', data, {
      headers: {
        requireAuthorization: true
      }
    });
  },

  getMe() {
    return httpClient.get('/auth/me', {
      headers: {
        requireAuthorization: true
      }
    });
  },

  updateMe(data) {
    return httpClient.patch('/auth/me', data, {
      headers: {
        requireAuthorization: true
      }
    });
  }
};

export default AuthRepository;