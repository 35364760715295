import React, { useState } from "react";
import cn from "classnames";
import Web3 from "web3";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMoralisDapp } from "../../providers/MoralisDappProvider";
import { useMoralis } from "react-moralis";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styles from "./ItemCreate.module.sass";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import FolowSteps from "./FolowSteps";

const Upload = () => {
  const { Moralis } = useMoralis();
  const { walletAddress, minterAddress } = useMoralisDapp();

  const history = useHistory();

  const [file, setFile] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const { t } = useTranslation('ItemCreate');

  const handeFileChange = (e) => {
    if (e.target?.files?.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const uploadToken = async (data) => {
    const { name, description } = data;

    setInProgress(true);

    const imageFile = new Moralis.File(file.name, file);

    await imageFile.saveIPFS();

    const imageURI = imageFile.ipfs();

    const metadataFile = new Moralis.File('metadata.json', {
      base64: btoa(JSON.stringify({
        name,
        description,
        image: imageURI,
      }))
    });

    await metadataFile.saveIPFS();

    const metadataURI = metadataFile.ipfs();

    try {
      await mintToken(metadataURI);

      history.push('/profile');
    } catch(_) {}

    setInProgress(false);
  }

  const mintToken = async (_uri) => {
    const encodedData = new Web3().eth.abi.encodeFunctionCall({
      name: 'mintToken',
      type: 'function',
      inputs: [{
        type: 'string',
        name: 'tokenURI',
      }],
    }, [_uri]);

    const txt = await Web3.givenProvider?.request({
      method: 'eth_sendTransaction',
      params: [{
        to: minterAddress,
        from: walletAddress,
        data: encodedData,
      }],
    });

    return txt;
  }

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.wrapper}>
            <div className={styles.head}>
              <div className={cn("h2", styles.title)}>
                {t('title')}
              </div>
            </div>
            <Formik
              initialValues={{
                name: '',
                description: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().trim().required(t('nameField.errors.required')),
                description: Yup.string().trim().required(t('descriptionField.errors.required')),
              })}
              onSubmit={(values) => {
                uploadToken(values);
              }}
            >
              {props => {
                const { handleSubmit, submitCount, isSubmitting, isValid } = props;
                const isSubmitDisabled = isSubmitting || (!isValid && submitCount > 1);

                return (
                  <Form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.list}>
                      {file ? (
                        <div>
                          <img
                            className={styles.fileimage}
                            src={URL.createObjectURL(file)}
                            alt="Thumb"
                          />
                        </div>
                      ) : (
                        <div className={styles.item}>
                          <div className={styles.category}>
                            {t('uploadFile.title')}
                          </div>
                          <div className={styles.note}>
                            {t('uploadFile.text')}
                          </div>
                          <div className={styles.file}>
                            <input
                              className={styles.load}
                              type="file"
                              onChange={handeFileChange}
                            />
                            <div className={styles.icon}>
                              <Icon name="upload-file" size="24" />
                            </div>
                            <div className={styles.format}>
                              PNG, GIF, WEBP or MP4
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={styles.item}>
                        <div className={styles.category}>
                          {t('itemDetails')}
                        </div>
                        <div className={styles.fieldset}>
                          <TextInput
                            className={styles.field}
                            label={t('nameField.label')}
                            name="name"
                            type="text"
                            placeholder={t('nameField.placeholder')}
                          />
                          <TextInput
                            className={styles.field}
                            label={t('descriptionField.label')}
                            name="description"
                            type="text"
                            placeholder={t('descriptionField.placeholder')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.blockchain}>
                      <div className={styles.blockchainTitle}>
                        {t('blockchain.title')}
                      </div>
                      <div className={styles.blockchainText}>
                        {t('blockchain.text')}
                      </div>
                      <div className={styles.blockchainBtns}>
                        <div className={styles.blockchainBtnWrap}>
                          <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnActive]: true })}>
                            <img
                              className={styles.blockchainBtnIcon}
                              src="/icons/polygon-token.svg"
                              alt="Polygon"
                            />
                            <span className={styles.blockchainBtnText}>Polygon</span>
                          </button>
                        </div>
                        <div className={styles.blockchainBtnWrap}>
                          <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnDisabled]: true })}>
                            <img
                              className={styles.blockchainBtnIcon}
                              src="/icons/ethereum-eth.svg"
                              alt="Ethereum"
                            />
                            <span className={styles.blockchainBtnText}>Еthereum</span>
                          </button>
                          <span className={styles.blockchainBtnNote}>{t('blockchain.comingSoon')}</span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.foot}>
                    {inProgress ? (
                        <div className={styles.saving}>
                          <span>{t('minting')}</span>
                          <Loader className={styles.loader} />
                        </div>
                    ) : (
                        <button
                          type="submit"
                          className={cn("button", styles.button)}
                          disabled={isSubmitDisabled}
                        >
                          <span>{t('createItem')}</span>
                          <Icon name="arrow-next" size="10" />
                        </button>
                    )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps className={styles.steps} />
      </Modal>
    </>
  );
};

export default Upload;
