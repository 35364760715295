import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./CreateMenu.module.sass";
import { useTranslation } from "react-i18next";

const items = [
  {
    title: "item",
    url: "/items/create",
  },
  {
    title: "collection",
    url: "/collections/create",
  },
];

const CreateMenu = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('Header');

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.notification, className)}>
        <button
          className={cn("button-small", styles.button)}
          onClick={() => setVisible(!visible)}
        >
          {t('CreateMenu.title')}
        </button>
        {visible && (
          <div className={styles.body}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  {t(`CreateMenu.items.${x.title}`)}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CreateMenu;
