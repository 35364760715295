import httpClient from "../utils/httpClient";

const ItemRepository = {
  create(data) {
    return httpClient.post('/items/many', data, {
      headers: {
        requireAuthorization: true
      }
    });
  },

  getOneWithData(contractAddress, tokenId){
    return httpClient.get(`/items/${contractAddress}/${tokenId}`)
  },

  getMany(collectionId) {
    return httpClient.get(`/collections/${collectionId}/items`);
  },

  search(query) {
    return httpClient.get(`/items/search?query=${query}`);
  },

  delete(id) {
    return httpClient.delete(`/collections/${id}`);
  }
};

export default ItemRepository;
