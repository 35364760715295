
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import ItemCreate from "./screens/ItemCreate"
import PageList from "./screens/PageList";
import Collections from "./screens/Collections"
import Collection from "./screens/Collection"
import CollectionCreate from "./screens/CollectionCreate"
import CollectionEdit from "./screens/CollectionEdit"
import Support from "./screens/Support"
import { ProtectedRoute } from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <Switch>
        <Route 
          exact 
          path="/"
        >
          <Page>
            <Home />
          </Page>
        </Route>
        <Route
          exact
          path="/collections"
        >
          <Page>
            <Collections />
          </Page>
        </Route>
        <Route
          exact
          path="/collections/create"
        >
          <ProtectedRoute>
            <Page>
              <CollectionCreate />
            </Page>
          </ProtectedRoute>
        </Route>
        <Route
          exact
          path="/collections/:query/edit"
        >
          <Page>
            <CollectionEdit />
          </Page>
        </Route>
        <Route
          exact
          path="/collections/:query"
        >
          <Page>
            <Collection />
          </Page>
        </Route>
        <Route
          exact
          path="/items/create"
        >
          <ProtectedRoute>
            <Page>
              <ItemCreate />
            </Page>
          </ProtectedRoute>
        </Route>
        <Route
          exact
          path="/upload-variants"
        >
          <Page>
            <UploadVariants />
          </Page>
        </Route>
        <Route
          exact
          path="/upload-details"
        >
          <Page>
            <UploadDetails />
          </Page>
        </Route>
        <Route
          exact
          path="/connect-wallet"
        >
          <Page>
            <ConnectWallet />
          </Page>
        </Route>
        <Route
          exact
          path="/faq"
        >
          <Page>
            <Faq />
          </Page>
        </Route>
        <Route
          exact
          path="/activity"
        >
          <Page>
            <Activity />
          </Page>
        </Route>
        <Route
          exact
          path="/search"
        >
          <Page>
            <Search01 />
          </Page>
        </Route>
        <Route
          exact
          path="/404"
        >
          <Page>
            <Search02 />
          </Page>
        </Route>
        <Route
          exact
          path="/profile"
        >
          <ProtectedRoute>
            <Page>
              <Profile />
            </Page>
          </ProtectedRoute>
        </Route>
        <Route
          exact
          path="/profile-edit"
        >
          <ProtectedRoute>
            <Page>
              <ProfileEdit />
            </Page>
          </ProtectedRoute>
        </Route>
        <Route
          exact
          path="/item/:contractAddress/:itemId"
        >
          <Page>
            <Item/>
          </Page>
        </Route>
        <Route
          exact
          path="/pagelist"
        >
          <Page>
            <PageList />
          </Page>
        </Route>
        <Route
          exact
          path="/support"
        >
          <Page>
            <Support />
          </Page>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
