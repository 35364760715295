import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import { convertedPrice } from "../../utils/utils";
import { useFavoriteItemsContext } from "../../providers/FavoriteItemsProvider";

const Card = (props) => {
    const { className, item, selectable = false, selected = false, onClick } = props;

    const [visible, setVisible] = useState(false);
    const [isSelected, setSelected] = useState(selected);
    const [showBuyButton, setShowBuyButton] = useState(false);

    const { favoriteItems, addFavoriteItem, deleteFavoriteItem } = useFavoriteItemsContext();

    useEffect(() => {
        if (item?.marketData) {
            setShowBuyButton(!item.marketData.sold);
        } else {
            setShowBuyButton(false);
        }

        setVisible(favoriteItems.some(
            (element) => element.contractAddress === item.token_address && element.tokenId === item.token_id)
        );
    }, [item, favoriteItems]);

    const handleClick = () => {
        if (!selectable) {
            return;
        }

        setSelected(!isSelected);

        onClick();
    };

    const handleFavoriteClick = (e) => {
        e.preventDefault();

        if (visible) {
            deleteFavoriteItem(item);
        } else {
            addFavoriteItem(item);
        }

        setVisible(!visible);
    }

    return (
        <div className={cn(styles.card, className)} onClick={() => handleClick()}>
            <Link
                onClick={(e) => {
                    if (!selectable) {
                        return;
                    }

                    e.preventDefault();
                }}
                to={`/item/${item?.token_address}/${item?.token_id}`}>
                <div className={styles.preview}>

                    <img srcSet={`${item.image}`} src={item.image} alt={item.name}/>
                    <div className={styles.control}>
                        <div
                            className={cn(
                                { 'status-green': item.category === 'green' },
                                styles.category
                            )}
                        >
                            {item.categoryText}
                        </div>

                        {showBuyButton ?
                            (<button
                                className={cn('button-small', styles.button)}>
                                <span>Buy now!</span>
                                <Icon name="bag" size="16"/>
                            </button>) : (<></>)}
                        {selectable ? (
                            <button
                                className={cn(styles.selector, { [styles.active]: visible })}
                            >
                                {(isSelected) ? (<Icon name="check" size="20"/>) : (<Icon name="plus" size="20"/>)}
                            </button>
                        ) : (
                            <button
                                className={cn(styles.favorite, { [styles.active]: visible })}
                                onClick={(e) => handleFavoriteClick(e)}>
                                <Icon name="heart" size="20"/>
                            </button>
                        )}
                    </div>
                </div>
            </Link>
            <Link className={styles.link} to={item.url || `/item/${item?.token_address}/${item?.token_id}`}>
                <div className={styles.body}>
                    <div className={styles.line}>
                        <div className={styles.title}>{item.metadata?.name}</div>
                        {item.price && showBuyButton && (
                            <div className={styles.price}>{convertedPrice(item?.price)}</div>
                        )}
                    </div>
                    <div className={styles.line}>
                        <div className={styles.user}>
                            <div className={styles.avatar}>
                                <img src={item.image} alt=""/>
                            </div>
                            <div className={styles.name}>{item.name}</div>
                        </div>
                        <div className={styles.id}>#{item.token_id}</div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Card;
