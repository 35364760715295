import { createContext, useState, useEffect, useContext } from 'react';
import { useMoralis } from "react-moralis";
import AuthService from '../services/AuthService';

export const MeContext = createContext({});

export const MeProvider = (props) => {
    const { children } = props;

    const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading, user } = useMoralis();
    const [me, setMe] = useState({});

    useEffect(() => {
        if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
            enableWeb3();
        }
    }, [enableWeb3, isAuthenticated, isWeb3EnableLoading, isWeb3Enabled]);

    useEffect(() => {
        if (!user) {
            return;
        }

        const initMe = async () => {
            try {
                const me = await AuthService.getMe(user);

                setMe(me);
            } catch (_) {
            }
        };

        initMe();
    }, [user]);


    return (
        <MeContext.Provider value={{ me, setMe }}>
            {children}
        </MeContext.Provider>
    );
}

export const useMeContext = () => {
    return useContext(MeContext);
}
