import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./PutSale.module.sass";
import {useMoralis, useWeb3ExecuteFunction} from "react-moralis";
import {useMoralisDapp} from "../../../../providers/MoralisDappProvider";
import LoaderCircle from "../../../../components/LoaderCircle";
import { useTranslation } from "react-i18next";



const PutSale = ({ className, nft }) => {
    const [price, setPrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const {marketAddress, contractABI, listItemFunction} = useMoralisDapp();
    const {Moralis} = useMoralis();
    const contractProcessor = useWeb3ExecuteFunction();
    const ItemImage = Moralis.Object.extend("ItemImages");
    const { t, ready } = useTranslation('Item');
    let items = [
        {
            title: t("PutSale.items.serviceFee"),
            value: "2.5%",
        },
    ];

    useEffect(() => {
        if (!ready) {
            return;
        }
        console.log()
    }, [ready, t]);

    const approveAll = async () => {
        setLoading(true);

        const ops = {
            contractAddress: nft.token_address,
            functionName: "setApprovalForAll",
            abi: [{
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "operator",
                        "type": "address"
                    },
                    {
                        "internalType": "bool",
                        "name": "approved",
                        "type": "bool"
                    }
                ],
                "name": "setApprovalForAll",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            }],
            params: {
                operator: marketAddress,
                approved: true
            },
        };

        await contractProcessor.fetch({
            params: ops,
            onSuccess: () => {
                setLoading(false);

                fetchList();
            },
            onError: (error) => {
                setLoading(false);
            },
        });
    }

    const fetchList = async () => {
        setLoading(true);

        await contractProcessor.fetch({
            params: {
                contractAddress: marketAddress,
                functionName: listItemFunction,
                abi: contractABI,
                params: {
                    nftContract: nft.token_address,
                    tokenId: nft.token_id,
                    price: String(price * 1e18),
                },
            },
            onSuccess: () => {
                setLoading(false);
                addItemImage();

                window.location.reload();
            },
            onError: (error) => {
                setLoading(false);
            },
        });
    }

    const addItemImage = () => {
        const itemImage = new ItemImage();

        itemImage.set("image", nft.image);
        itemImage.set("nftContract", nft.token_address);
        itemImage.set("tokenId", nft.token_id);
        itemImage.set("name", nft.name);

        itemImage.save();
    }

    return (
        <div className={cn(className, styles.sale)}>
            {loading && <div className={styles.line}>
                <div className={styles.icon}>
                    <LoaderCircle className={styles.loader}/>
                </div>
                <div className={styles.details}>
                    <div className={styles.subtitle}>
                        {t('PutSale.loading.subtitle')}
                    </div>
                    <div className={styles.text}>
                        {t('PutSale.loading.text')}
                    </div>
                </div>
            </div>}

            {!loading && <>
                <div className={cn("h4", styles.title)}>
                    {t('PutSale.title')}
                </div>
                <div className={styles.table}>
                    <div className={styles.field}>
                        <input
                            className={styles.input}
                            type="number"
                            name="address"
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder={t('PutSale.addressField.placeholder')}
                        />
                    </div>
                    {items.map((x, index) => (
                        <div className={styles.row} key={index}>
                            <div className={styles.col}>{x.title}</div>
                            <div className={styles.col}>{x.value}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.btns}>
                    <button className={cn("button", styles.button)} onClick={() => approveAll()}>
                        {t('PutSale.continue')}
                    </button>
                    <button className={cn("button-stroke", styles.button)}>
                        {t('PutSale.cancel')}
                    </button>
                </div>
            </>}
        </div>
    );
};

export default PutSale;
