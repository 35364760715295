import React, { useState } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import { useWeb3Transfer } from "react-moralis";
import { useTranslation } from "react-i18next";

const Transfer = ({className, nft}) => {
    const [address, setAddress] = useState('');
    const {fetch, error, isFetching} = useWeb3Transfer({
        type: nft.type,
        receiver: address,
        contractAddress: nft.contractAddress,
        tokenId: nft.tokenId,
        amount: 1
    });
    const { t } = useTranslation('Transfer');

    return (
        <div className={cn(className, styles.transfer)}>
            <div className={cn("h4", styles.title)}>
                {t('title')}
            </div>
            <div className={styles.text}>
                {t('text')}
            </div>
            <div className={styles.info}>
                {t('info')}
            </div>
            <div className={styles.field}>
                <input
                    className={styles.input}
                    type="text"
                    name={t('addressField.label')}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder={t('addressField.placeholder')}
                />
            </div>
            <div className={styles.btns}>
                {error && <button>{error.toString()}</button>}
                <button
                    className={cn("button", styles.button)}
                    disabled={isFetching}
                    onClick={() => fetch()}
                >
                    {t('continue')}
                </button>
                <button className={cn("button-stroke", styles.button)}>
                    {t('cancel')}
                </button>
            </div>
        </div>
    );
};

export default Transfer;
