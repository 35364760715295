import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import Identicon from "react-identicons";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Loader from "../../components/Loader";
import AuthService from "../../services/AuthService";
import { useMeContext } from "../../providers/MeProvider";
import { useToastContext } from "../../providers/ToastProvider";
import { Trans, useTranslation } from "react-i18next";
import {urlExp} from "../../utils/utils";

const breadcrumbsInitial = [
  {
    title: "Control.breadcrumbs.home",
    url: "/",
  },
  {
    title: "Control.breadcrumbs.editProfile",
  },
];

const ProfileControl = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState(breadcrumbsInitial);
  const { t, ready } = useTranslation('ProfileEdit');

  useEffect(() => {
    if (!ready) {
      return;
    }

    setBreadcrumbs(breadcrumbs.map((item) => ({ ...item, ...{ title: t(item.title) } })));
  }, [ready]);

  return (
    <Control className={styles.control} item={breadcrumbs} />
  );
}

const ProfileAvatar = (props) => {
  const { url, walletAddress } = props;

  if (!url) {
    return (
      <div className={styles.avatar}>
        <Identicon string={walletAddress} size={128} className={styles.identicon} />
      </div>
    );
  }

  return (
    <div className={styles.avatar}>
      <img src={url} alt={walletAddress} />
    </div>
  );
}

const ProfileEdit = () => {
  const [avatarUrl, setAvatarUrl] = useState();

  const { me, setMe } = useMeContext();
  const toast = useToastContext();

  const avatarUploadRef = useRef(null);

  const { t } = useTranslation('ProfileEdit');

  const handleUploadAvatar = (event, setValue) => {
    const file = event.target.files[0];

    setAvatarUrl(URL.createObjectURL(file));

    setValue('avatar', event.target.files[0]);
  }

  useEffect(() => {
    if (!me) {
      return;
    }

    setAvatarUrl(me.avatar);
  }, [me]);

  return (
    <div className={styles.page}>
      <ProfileControl />
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>{t('title')}</h1>
            <div className={styles.info}>
              <Trans i18nKey="info" t={t} components={[<strong/>]}/>
            </div>
          </div>
          {me.username ? (<Formik
            initialValues={{
              avatar: undefined,
              name: me.name,
              customUrl: me.customUrl,
              description: me.description,
              socialLinks: me.socialLinks,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim(),
              customUrl: Yup.string().trim().matches(urlExp,t('customUrlField.errors.url')),
              description: Yup.string().trim(),
              socialLinks: Yup.object({
                twitter: Yup.string().trim().matches(urlExp,t('twitterField.errors.url')),
                telegram: Yup.string().trim().matches(urlExp,t('telegramField.errors.url')),
                instagram: Yup.string().trim().matches(urlExp,t('instagramField.errors.url')),
              }),
            })}
            onSubmit={async (values) => {
              try {
                const { data } = await AuthService.updateMe(values);

                setMe(data)

                toast.success(t('toast.success'));
              } catch(_) {
                toast.error(t('toast.error'));
              }
            }}
          >
            {props => {
              const { setFieldValue, handleSubmit, submitCount, isSubmitting, isValid } = props;

              const isSubmitDisabled = isSubmitting || (!isValid && submitCount > 1);

              return (
                <Form onSubmit={handleSubmit}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <div className={styles.user}>
                        <ProfileAvatar url={avatarUrl} walletAddress={me.wallets?.ethAddress} />
                        <div className={styles.details}>
                          <div className={styles.stage}>{t('avatar.title')}</div>
                          <div className={styles.text}>
                            <Trans i18nKey="avatar.text" t={t} components={[<span role="img" aria-label="hooray"/>]}/>
                          </div>
                          <div className={styles.file}>
                            <button
                              type="button"
                              className={cn(
                                "button-stroke button-small",
                                styles.button
                              )}
                              onClick={(e) => avatarUploadRef.current.click()}
                            >
                              {t('avatar.upload')}
                            </button>
                            <input
                              ref={avatarUploadRef}
                              className={styles.load}
                              type="file"
                              onChange={(e) => handleUploadAvatar(e, setFieldValue)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.list}>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('sectionInfo')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label={t('nameField.label')}
                              name="name"
                              type="text"
                              placeholder={t('nameField.placeholder')}
                            />
                            <TextInput
                              className={styles.field}
                              label={t('customUrlField.label')}
                              name="customUrl"
                              type="text"
                              placeholder="https://example.com/"
                            />
                            <TextArea
                              className={styles.field}
                              label={t('descriptionField.label')}
                              name="description"
                              placeholder={t('descriptionField.placeholder')}
                            />
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('sectionSocial')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label="twitter"
                              name="socialLinks.twitter"
                              type="text"
                              placeholder="https://twitter.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="instagram"
                              name="socialLinks.instagram"
                              type="text"
                              placeholder="https://instagram.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="telegram"
                              name="socialLinks.telegram"
                              type="text"
                              placeholder="https://t.me"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.note}>
                        {t('note')}
                      </div>
                      <div className={styles.btns}>
                        <button
                          type="submit"
                          className={cn("button", styles.button, styles.submit)}
                          disabled={isSubmitDisabled}
                        >
                          {t('submit')}
                        </button>
                        {isSubmitting && <Loader className={styles.submitLoader}/>}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>) : (<Loader className={styles.loader}/>)}
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
