import React, {useState, useEffect} from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCollectedItems } from "../../hooks/useCollectedItems";
import { useOnSaleItems } from "../../hooks/useOnSaleItems";
import { useMeContext } from "../../providers/MeProvider";
import { useToastContext } from "../../providers/ToastProvider";
import AuthService from "../../services/AuthService";
import styles from "./Profile.module.sass";
import Collections from "./Collections";
import Icon from "../../components/Icon";
import User from "./User";
import Items from "./Items";
import { useFavoriteItems } from "../../hooks/useFavoriteItems";
import { useCreatedItems } from "../../hooks/useCreatedItems";
import Loader from "../../components/Loader";


const ProfileBanner = ({children, visible}) => {
    const { me } = useMeContext();

    return (
        <div
            className={cn(styles.head, {[styles.active]: visible})}
            style={{
                backgroundImage: `url(${me.newBanner || me.banner || '/images/content/bg-profile.jpg'})`,
            }}
        >
            {children}
        </div>
    );
}

const ProfileBannerUpload = (props) => {
    const { onSave } = props;

    const { me, setMe } = useMeContext();
    const toast = useToastContext()
    const [bannerFile, setBannerFile] = useState();
    const [isBannerSaving, setIsBannerSaving] = useState();
    const { t } = useTranslation('Profile');


    const handleUploadBanner = (e) => {
        if (bannerFile) {
            URL.revokeObjectURL(bannerFile);
        }

        const file = e.target.files[0];

        setBannerFile(file);

        setMe({ ...me, newBanner: URL.createObjectURL(file) });
    }

    const handleSaveBanner = async () => {
        if (!bannerFile) {
            onSave();

            return;
        }

        setIsBannerSaving(true);

        try {
            await AuthService.updateMe({ banner: bannerFile });

            toast.success(t('Banner.toast.success'));
        } catch (_) {
            setMe({ ...me, newBanner: null });

            toast.error(t('Banner.toast.error'));
        }

        URL.revokeObjectURL(bannerFile);

        setBannerFile(null);

        setIsBannerSaving(false);

        onSave();
    }

    return (
        <div className={styles.file}>
            <input type="file" onChange={(e) => handleUploadBanner(e)}/>
            <div className={styles.wrap}>
                <Icon name="upload-file" size="48"/>
                <div className={styles.info}>{t('Banner.info')}</div>
                <div className={styles.text}>{t('Banner.text')}</div>
            </div>
            <button
                className={cn("button-small", styles.button)}
                disabled={isBannerSaving}
                onClick={() => handleSaveBanner()}
            >
                {isBannerSaving ? <Loader color={'white'} /> : t('Banner.save')}
            </button>
        </div>
    );
}

const OnSaleItems = () => {
    const { onSaleItems, isLoading } = useOnSaleItems();

    return (
        <Items
            class={styles.items}
            items={onSaleItems}
            isLoading={isLoading}
        />
    );
}

const CollectedItems = () => {
    const { collectedItems, isLoading } = useCollectedItems();

    return (
        <Items
            class={styles.items}
            items={collectedItems}
            isLoading={isLoading}
        />
    );
};

const CreatedItems = () => {
    const { createdItems, isLoading } = useCreatedItems();

    return (
        <Items 
            class={styles.items}
            items={createdItems}
            isLoading={isLoading}
        />
    );
}

const FavoriteItems = () => {
    const { favoriteItems, isLoading } = useFavoriteItems();

    return (
        <Items
            class={styles.items}
            items={favoriteItems}
            isLoading={isLoading}
        />
    );
}

const Profile = () => {
    const { t } = useTranslation('Profile');
    const [activeIndex, setActiveIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const navLinks = [
        t('NavMenu.onSale'),
        t('NavMenu.collected'),
        t('NavMenu.created'),
        t('NavMenu.collections'),
        t('NavMenu.favorites')
    ];

    useEffect(() => {
        if (!location?.state?.activeIndex) {
            return;
        }

        const initActiveIndex = () => {
            setActiveIndex(location.state.activeIndex);
        }

        initActiveIndex();
    }, [location]);

    return (
        <div className={styles.profile}>
            <ProfileBanner visible={visible}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.btns}>
                        <button 
                            className={cn("button-stroke button-small", styles.button)} 
                            onClick={() => setVisible(true)}
                        >
                            <span>{t('Banner.edit')}</span>
                            <Icon name="image" size="16"/>
                        </button>
                        <Link className={cn("button-stroke button-small", styles.button)} to="profile-edit">
                            <span>{t('edit')}</span>
                            <Icon name="edit" size="16"/>
                        </Link>
                    </div>
                    <ProfileBannerUpload onSave={() => setVisible(false)}/>
                </div>
            </ProfileBanner>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <User className={styles.user}/>
                    <div className={styles.wrapper}>
                        <div className={styles.nav}>
                            {navLinks.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: index === activeIndex,
                                    })}
                                    key={index}
                                    onClick={() => {
                                        setActiveIndex(index)
                                    }}
                                >
                                    {x}
                                </button>
                            ))}
                        </div>
                        <div className={styles.group}>
                            <div className={styles.item}>
                                {activeIndex === 0 && (
                                    <OnSaleItems/>
                                )}
                                {activeIndex === 1 && (
                                    <CollectedItems/>
                                )}
                                {activeIndex === 2 && (
                                    <CreatedItems/>
                                )}
                                {activeIndex === 3 && (
                                    <Collections className={styles.collections}/>
                                )}
                                {activeIndex === 4 && (
                                    <FavoriteItems/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
