import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import * as Yup from 'yup';
import {Formik, Form, useField} from 'formik';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import styles from "./CollectionCreate.module.sass";
import Category from "./Category";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import CollectionService from "../../services/CollectionService";
import { useToastContext } from "../../providers/ToastProvider";
import Loader from "../../components/Loader";
import {urlExp} from "../../utils/utils";

const CollectionLogo = (props) => {
  const { onChange, value } = props;
  const [field, meta] = useField(props);

  const { t } = useTranslation('CollectionCreate');
  const [url, setUrl] = useState('');
  const uploadRef = useRef(null);

  useEffect(() => {
    if (!value) {
      setUrl('');
    }
  }, [value]);

  const handleUpload = (e) => {
    const file = e.target.files[0];

    setUrl(URL.createObjectURL(file));

    onChange(file);
  };

  return (
    <div className={styles.user}>
      <div className={styles.avatar}>
        {url ? <img src={url} alt={t('logo.stage')} /> : (<div className={styles.avatarEmpty} onClick={() => uploadRef.current.click()}>
          <div className={styles.avatarEmptyFormat}>
            PNG, GIF, JPG
          </div>
        </div>)}
      </div>
      <div className={styles.details}>
        <div className={styles.stage}>
          {t('logo.stage')}
        </div>
        <div className={styles.text}>
          {t('logo.text')}
        </div>
        <div className={styles.file}>
          <button
            type="button"
            className={cn(
              "button-stroke button-small",
              styles.button
            )}
            onClick={() => uploadRef.current.click()}
          >
            {t('button')}
          </button>
          <input
            className={styles.load}
            type="file"
            ref={uploadRef}
            onChange={(e) => handleUpload(e)}
          />
        </div>
        {meta.touched && meta.error ? (
            <div className={styles.error}>{meta.error}</div>
        ) : null}
      </div>

    </div>
  );
}

const CollectionBanner = (props) => {
  const { onChange, value } = props;
  const [field, meta] = useField(props);

  const { t } = useTranslation('CollectionCreate');
  const [url, setUrl] = useState('');
  const uploadRef = useRef(null);

  useEffect(() => {
    if (!value) {
      setUrl('');
    }
  }, [value]);

  const handleUpload = (e) => {
    const file = e.target.files[0];

    setUrl(URL.createObjectURL(file));

    onChange(file);
  };

  return (
    <div className={styles.cover}>
      <div className={styles.coverTitle}>
        {t('cover.title')}
      </div>
      <div className={styles.coverText}>
        {t('cover.text')}
      </div>
      <div
        className={styles.coverFile}
        onClick={() => uploadRef.current.click()}
        style={{
          backgroundImage: url ? `url(${url})` : 'none'
        }}
      >
        <input
          className={styles.coverFileLoad}
          type="file"
          ref={uploadRef}
          onChange={(e) => handleUpload(e)}
        />
        <div className={styles.coverFileIcon}>
          <Icon name="upload-file" size="24" />
        </div>
        <div className={styles.coverFileFormat}>
          PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
        </div>
      </div>
      {meta.touched && meta.error ? (
          <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
}

const CollectionCreate = () => {
  const { t } = useTranslation('CollectionCreate');
  const toast = useToastContext();
  const history = useHistory();

  return (
    <div className={styles.page}>
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>{t('title')}</h1>
          </div>
          <Formik
            initialValues={{
              logo: undefined,
              banner: undefined,
              name: '',
              slug: '',
              description: '',
              category: '',
              customUrl: '',
              socialLinks: {
                twitter: '',
                instagram: '',
                telegram: ''
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().required(t('nameField.errors.required')),
              slug: Yup.string().trim().required(t('slugField.errors.required')),
              description: Yup.string().trim(),
              customUrl: Yup.string().trim().matches(urlExp, t('customUrlField.errors.url')),
              category: Yup.string().trim().required(t('categoryField.errors.required')),
              socialLinks: Yup.object({
                twitter: Yup.string().trim().matches(urlExp, t('twitterField.errors.url')),
                telegram: Yup.string().trim().matches(urlExp, t('telegramField.errors.url')),
                instagram: Yup.string().trim().matches(urlExp, t('instagramField.errors.url')),
              }),
              logo: Yup.string().trim().required(t('logo.errors.required')),
              banner: Yup.string().trim().required(t('cover.errors.required')),
            })}
            onSubmit={async (values, { resetForm }) => {
              try {
                await CollectionService.create(values);

                resetForm();

                toast.success(t('toast.success'));

                history.push('/profile', { activeIndex: 3 });
              } catch(_) {
                toast.error(t('toast.error'));
              }
            }}
          >
            {props => {
              const { setFieldValue, setFieldTouched, getFieldProps, getFieldMeta, handleSubmit, submitCount, isSubmitting, isValid } = props;

              const isSubmitDisabled = isSubmitting || (!isValid && submitCount > 1);

              return (
                <Form onSubmit={(e) => {
                  setFieldTouched('category');

                  handleSubmit(e);
                }}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <CollectionLogo {...getFieldProps('logo')} onChange={(file) => setFieldValue('logo', file)} required/>
                      <CollectionBanner {...getFieldProps('banner')} onChange={(file) => setFieldValue('banner', file)} required/>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.list}>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('mainInfo')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label={t('nameField.label')}
                              name="name"
                              type="text"
                              placeholder={t('nameField.placeholder')}
                            />
                            <TextInput
                              className={styles.field}
                              label={t('slugField.label')}
                              name="slug"
                              type="text"
                              placeholder="nft.kvell.group/collections/[URL Slug]"
                            />
                            <TextArea
                              className={styles.field}
                              label={t('descriptionField.label')}
                              name="description"
                              placeholder={t('descriptionField.placeholder')}
                            />
                            <div className={styles.label}>{t('categoryField.label')}</div>
                            <Category
                              meta={getFieldMeta('category')}
                              selected={getFieldProps('category').value}
                              onSelect={(id) => setFieldValue('category', id)}
                            />
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.category}>{t('social')}</div>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label={t('customUrlField.label')}
                              name="customUrl"
                              type="text"
                              placeholder={t('customUrlField.placeholder')}
                            />
                            <TextInput
                              className={styles.field}
                              label="Twitter"
                              name="socialLinks.twitter"
                              type="text"
                              placeholder="https://twitter.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="Instagram"
                              name="socialLinks.instagram"
                              type="text"
                              placeholder="https://instagram.com"
                            />
                            <TextInput
                              className={styles.field}
                              label="Telegram"
                              name="socialLinks.telegram"
                              type="text"
                              placeholder="https://t.me"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.blockchain}>
                        <div className={styles.blockchainTitle}>
                          {t('blockchain.title')}
                        </div>
                        <div className={styles.blockchainText}>
                          {t('blockchain.text')}
                        </div>
                        <div className={styles.blockchainBtns}>
                          <div className={styles.blockchainBtnWrap}>
                            <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnActive]: true })}>
                              <img
                                className={styles.blockchainBtnIcon}
                                src="/icons/polygon-token.svg"
                                alt="Polygon"
                              />
                              <span className={styles.blockchainBtnText}>Polygon</span>
                            </button>
                          </div>
                          <div className={styles.blockchainBtnWrap}>
                            <button type="button" className={cn(styles.blockchainBtn, { [styles.blockchainBtnDisabled]: true })}>
                              <img
                                className={styles.blockchainBtnIcon}
                                src="/icons/ethereum-eth.svg"
                                alt="Etherium"
                              />
                              <span className={styles.blockchainBtnText}>Ethereum</span>
                            </button>
                            <span className={styles.blockchainBtnNote}>{t('blockchain.note')}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.btns}>
                        <button className={cn("button", styles.button)} type="submit" disabled={isSubmitDisabled}>
                          {t('submit')}
                        </button>
                        {isSubmitting && <Loader className={styles.submitLoader}/>}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CollectionCreate;
