import { useEffect, useState } from "react"
import UserService from "../services/UserService";
import {useMeContext} from "../providers/MeProvider";

export const useUserCollections = () => {
    const [collections, setCollections] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const { me } = useMeContext();

    useEffect(() => {
        const getCollections = async () => {
            setIsLoading(true);

            try {
                const { data } = await UserService.getCollections();
                let userCollections = data.filter(item => item.user._id === me._id);
                setCollections(userCollections);
            } catch(_) {
                setCollections([]);
            }

            setIsLoading(false);
        }

        getCollections();
    }, []);

    return { collections, isLoading };
};
