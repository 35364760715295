import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const useQuery = () =>  {
    const [query, setQeury] = useState({});
    const search = useLocation().search.slice(1);

    useEffect(() => {
        setQeury(() => {
            const query = new URLSearchParams(search);
            const result = {};
            for (let [key, value] of query.entries()) {
                result[key] = value;
            }
            setQeury(result);
        }, [search]);
    }, [search, setQeury]);

    return {...query};
};