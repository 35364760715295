import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import CollectionService from "../../services/CollectionService";
import { useToastContext } from "../../providers/ToastProvider";
import { useCollectionItems } from "../../hooks/useCollectionItems";
import styles from "./Collection.module.sass";
import User from "./User";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import AddItems from "./AddItems";
import Items from "./Items";
import { useMeContext } from "../../providers/MeProvider";
import { useTranslation } from "react-i18next";

const dateOptionsInitial = [
  {
    label: 'dateFilter.options.newest',
    value: 'newest'
  },
  {
    label: 'dateFilter.options.oldest',
    value: 'oldest'
  }
];

const CollectionBannerUpload = (props) => {
  const { collection, onUpload, onSave } = props;

  const toast = useToastContext()
  const [file, setFile] = useState();
  const [isFileSaving, setIsFileSaving] = useState();
  const { t } = useTranslation('Collection');

  const handleUpload = (e) => {
    if (file) {
      URL.revokeObjectURL(file);
    }

    const uploadedFile = e.target.files[0];

    if (!uploadedFile) {
      return;
    }

    setFile(uploadedFile);

    onUpload(URL.createObjectURL(uploadedFile));
  }

  const handleSave = async () => {
    if (!file) {
      onSave();

      return;
    }

    setIsFileSaving(true);

    try {
      await CollectionService.update(collection.id, {
        banner: file,
        name: collection.name,
        slug: collection.slug,
        category: collection.category,
      });

      toast.success(t('BannerUpload.toast.success'));
    } catch(_) {
      onUpload(collection.banner);

      toast.error(t('BannerUpload.toast.error'));
    }

    URL.revokeObjectURL(file);

    setFile(null);

    setIsFileSaving(false);

    onSave();
  }

  return (
    <div className={styles.file}>
      <input type="file" onChange={(e) => handleUpload(e)}/>
      <div className={styles.wrap}>
        <Icon name="upload-file" size="48"/>
        <div className={styles.info}>{t('BannerUpload.info')}</div>
        <div className={styles.text}>{t('BannerUpload.text')}</div>
      </div>
      <button
        className={cn("button-small", styles.button)}
        onClick={() => handleSave()}
        disabled={isFileSaving}
      >
        {isFileSaving ? <Loader color={'white'} className={styles.loader} /> : t('BannerUpload.save')}
      </button>
    </div>
  );
}

const CollectionItems = (props) => {
  const { items, isLoading } = props;

  const [dateOptions, setDateOptions] = useState(dateOptionsInitial);
  const [date, setDate] = useState(dateOptions[0]);

  const isSortByNewest = (value) => value === 'newest';

  const sortByOldest = (items) => [...items].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  const sortByNewest = (items) => [...items].sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

  const getSortedByDate = (date, items) => isSortByNewest(date.value) ? sortByNewest(items) : sortByOldest(items);

  const { t, ready } = useTranslation('Collection');

  useEffect(() => {
    if (!ready) {
      return;
    }

    setDateOptions(dateOptionsInitial.map((option) => ({ ...option, ...{ label: t(option.label) } })));
  }, [ready]);

  useEffect(() => {
    setDate(dateOptions[0]);
  }, [dateOptions]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sorting}>
        <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={date}
            setValue={(value) => setDate(value)}
            options={dateOptions}
          />
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.item}>
          {isLoading ? <Loader className={styles.itemsLoader}/> : <Items class={styles.items} items={getSortedByDate(date, items)} />}
        </div>
      </div>
    </div>
  );
}

const CollectionActions = (props) => {
  const { collectionSlug, collectionUserId, onAddItems, onEditCoverPhoto } = props;

  const { me } = useMeContext();
  const { t } = useTranslation('Collection');

  if (me._id !== collectionUserId) {
    return null;
  }

  return (
    <div className={styles.btns}>
      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => onAddItems()}
      >
        <span>{t('Actions.addItems')}</span>
        <Icon name="plus" size="16" />
      </button>
      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => onEditCoverPhoto()}
      >
        <span>{t('Actions.editCoverPhoto')}</span>
        <Icon name="image" size="16" />
      </button>

      <Link
        className={cn("button-stroke button-small", styles.button)}
        to={`/collections/${collectionSlug}/edit`}
      >
        <span>{t('Actions.editCollection')}</span>
        <Icon name="edit" size="16" />
      </Link>
    </div>
  );
}

const Collection = () => {
  const [visible, setVisible] = useState(false);
  const [visibleModalAddItems, setVisibleModalAddItems] = useState(false);
  const { query } = useParams();
  const [collection, setCollection] = useState({});
  const { collectionItems, isCollectionItemsLoading } = useCollectionItems(collection._id);
  
  useEffect(() => {
    if (!query) {
      return;
    }

    const init = async () => {
      try {
        const { data } = await CollectionService.getOne(query);

        setCollection(data);
      } catch(_) {
        setCollection({});
      }
    };

    init();
  }, [query]);

  const handleUploadBanner = (url) => {
    setCollection({ ...collection, banner: url });
  }

  return (
    <>
      <div className={styles.profile}>
        <div
          className={cn(styles.head, { [styles.active]: visible })}
          style={{
            backgroundImage: collection.banner ? `url(${collection.banner})` : 'none',
          }}
        >
          <div className={cn("container", styles.container)}>
            <CollectionActions
              collectionSlug={collection.slug}
              collectionUserId={collection.user?._id}
              onAddItems={() => setVisibleModalAddItems(true)}
              onEditCoverPhoto={() => setVisible(true)}
            />
            <CollectionBannerUpload
              collection={collection}
              onUpload={(url) => handleUploadBanner(url)}
              onSave={() => setVisible(false)}
            />
          </div>
        </div>
        <div className={styles.body}>
          <div className={cn("container", styles.container)}>
            <div className={styles.user}>
              <User collection={collection} />
            </div>
            <CollectionItems items={collectionItems} isLoading={isCollectionItemsLoading} />
          </div>
        </div>
      </div>
      <Modal
        outerClassName={styles.outerbig}
        visible={visibleModalAddItems}
        onClose={() => setVisibleModalAddItems(false)}
      >
        <AddItems collectionId={collection._id}/>
      </Modal>
    </>
  );
};

export default Collection;
