import { useEffect, useState } from "react"

import CategoryService from "../services/CategoryService";
import ItemService from "../services/ItemService";

export const useNftData = (contractAddress, tokenId) => {
    const [nft, setNft] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        const getNftData = async () => {
            setIsLoading(true);
            try {
                const { data } = await ItemService.getOneWithData(contractAddress, tokenId);

                setNft(data[0]);
            } catch(_) {
                setNft(null);
            }

            setIsLoading(false);
        }
        getNftData();
    }, []);

    return { nft, isLoading };
};
