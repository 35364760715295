import React, {useState} from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Share.module.sass";
import Icon from "../Icon";
import {openInNewTab} from "../../utils/utils";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";

const Share = (props) => {
    const { className } = props;

    const { t } = useTranslation('Share');
    const [visible, setVisible] = useState(false);

    const items = [
        {
            title: t('copyLink'),
            icon: 'copy',
            action: () => {
                navigator.clipboard.writeText(window.location.href)
                    .then(() => {
                        toast.success('copied')
                    })
                setVisible(false)
            },
        },
        {
            title: t('shareFacebook'),
            icon: 'facebook',
            action: () => {
                openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
                setVisible(false)
            },
        },
        {
            title: t('shareTwitter'),
            icon: 'twitter',
            action: () => {
                openInNewTab(`https://twitter.com/intent/tweet?url=${window.location.href}`)
                setVisible(false)
            },
        },
    ];

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div
                    className={cn(styles.actions, className, {
                        [styles.active]: visible,
                    })}
                >
                    <button
                        className={cn("button-circle-stroke", styles.button)}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon name="share" size="24"/>
                    </button>
                    <div className={styles.body}>
                        {items.map((x, index) => (
                            <div className={styles.item} key={index} onClick={x.action}>
                                <Icon name={x.icon} size="20"/>
                                <span>{x.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    );
};

export default Share;
