import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";



const Hero = () => {
  const { t } = useTranslation('FAQ');
  const items = [
    {
      id: "general",
      icon: "home",
      items: [
        {title:t('faqFirstTitle'), value: t('faqFirstValue')},
        {title:t('faqSecondTitle'), value: t('faqSecondValue')},
        {title:t('faqThirdTitle'), value: t('faqThirdValue')},
      ],
    },
  ];

  const options = [];
  items.map((x) => options.push({
    value: x.id,
    label: t(`nav.titles.${x.id}`)
  }));

  const [direction, setDirection] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.stage}>{t('stage')}</div>
          <h1 className={cn("h2", styles.title)}>{t('title')}</h1>
          <div className={styles.info}>
            <Trans i18nKey="info" t={t} components={[<NavLink to="/support"/>]} />
          </div>
          <Dropdown
            className={cn("mobile-show", styles.dropdown)}
            value={direction}
            setValue={setDirection}
            options={options}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.nav}>
              {items.map((x, index) => (
                <div
                  className={cn(styles.link, {
                    [styles.active]: x.id === direction.value,
                  })}
                  onClick={() => setDirection(x.id)}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  <span>{t(`nav.titles.${x.id}`)}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            {items
              .find((x) => x.id === direction.value)
              .items
              .map((x, index) => (
                <Item className={styles.item} item={x} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
