const setAccessToken = (token) => {
    return localStorage.setItem('nftAccessToken', token);
};

const getAccessToken = () => {
    return localStorage.getItem('nftAccessToken');
};

const deleteAccessToken = () => {
    localStorage.removeItem('nftAccessToken');
};

const setRefreshToken = (token) => {
    localStorage.setItem('nftRefreshToken', token);
};

const getRefreshToken = () => {
    return localStorage.getItem('nftRefreshToken');
};

const deleteRefreshToken = () => {
    return localStorage.removeItem('nftRefreshToken');
};

export {
    setAccessToken,
    getAccessToken,
    deleteAccessToken,
    setRefreshToken,
    getRefreshToken,
    deleteRefreshToken,
};