 
import { Redirect, useLocation } from 'react-router-dom';
import { getAccessToken } from '../../utils/storage';

export const ProtectedRoute = (props) => {
    const { children, redirectPath = '/' } = props;

    const { pathname } = useLocation();

    return getAccessToken() ? children : <Redirect to={redirectPath} from={pathname} />;
}