import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import styles from "./Collections.module.sass";
import Loader from "../../../components/Loader";
import { useUserCollections } from "../../../hooks/useUserCollections";

const Collections = ({ className }) => {
  const { collections, isLoading } = useUserCollections();
  const { t } = useTranslation('Profile');

  return (
    <div className={cn(styles.collections, className)}>
      <div className={styles.list}>
        {isLoading && <Loader className={styles.loader} />}
        {!isLoading && collections.map((collection, index) => (
          <Link className={styles.card} to={`/collections/${collection.slug}`} key={index}>
            <img className={styles.banner} src={collection.banner} alt={collection.name} />
            <div className={styles.subtitle}>{collection.name}</div>
            <div className={styles.line}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                  <img src={collection.user.avatar} alt="" />
                </div>
                <div className={styles.author}>
                  <Trans
                    i18nKey="Collections.author"
                    t={t}
                    values={{ name: collection.user.name }}
                    components={[<span className={styles.authorName}/>]}
                  />
                </div>
              </div>
              <div className={cn("status-stroke-black", styles.counter)}>
                <Trans
                  i18nKey="Collections.counter"
                  t={t}
                  values={{ counter: collection.itemsCount }}
                  components={[<span/>]}
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Collections;
