import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import cn from "classnames";
import {useCollections} from "../../hooks/useCollections";
import {useCategoriesContext} from "../../providers/CategoriesProvider";
import styles from "./Collections.module.sass";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import {Trans, useTranslation} from "react-i18next";
import { EN_LANGUAGE_CODE } from "../../constants/i18n";

const dateOptionsInitial = [
    {
        label: 'dateFilter.options.newest',
        value: 'newest',
    },
    {
        label: 'dateFilter.options.oldest',
        value: 'oldest'
    }
];

const Collections = () => {
    const location = useLocation();
    const {t, ready, i18n} = useTranslation('Collections');
    const {collections, isLoading: isCollectionsLoading} = useCollections();
    const {categories, isLoading: isCategoriesLoading} = useCategoriesContext();

    const [activeCategory, setActiveCategory] = useState('');
    const [dateOptions, setDateOptions] = useState(dateOptionsInitial);
    const [date, setDate] = useState(dateOptions[0]);

    const isCollectionActive = (id) => activeCategory ? activeCategory === id : true;

    const isSortByNewest = (value) => value === 'newest';

    const sortByNewest = (collections) => [...collections].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const sortByOldest = (collections) => [...collections].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    const getSortedByDate = (date, collections) => isSortByNewest(date.value) ? sortByNewest(collections) : sortByOldest(collections);

    useEffect(() => {
        if (!location?.state?.categoryId) {
            return;
        }

        const initActiveCategory = () => {
            setActiveCategory(location.state.categoryId);
        }

        initActiveCategory();
    }, [location]);

    useEffect(() => {
        if (!ready) {
            return;
        }

        setDateOptions(dateOptionsInitial.map((option) => ({...option, ...{label: t(option.label)}})));
    }, [ready]);

    useEffect(() => {
        setDate(dateOptions[0]);
    }, [dateOptions]);

    if (isCategoriesLoading || isCollectionsLoading) {
        return (
            <div className={cn("section-pt80", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={cn("h3", styles.title)}>{t('title')}</div>
                    <Loader className={styles.loader}/>
                </div>
            </div>
        );
    }

    return (
        <div className={cn("section-pt80", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={cn("h3", styles.title)}>{t('title')}</div>
                <div className={styles.sorting}>
                    <div className={styles.nav}>
                        {[...[{_id: '', name: t('all'), nameRu: t('all')}], ...categories].map((category, index) => (
                            <button
                                className={cn(styles.link, {
                                    [styles.active]: category._id === activeCategory,
                                })}
                                onClick={() => setActiveCategory(category._id)}
                                key={index}
                            >
                                {i18n.language === EN_LANGUAGE_CODE ? category.name : category.nameRu}
                            </button>
                        ))}
                    </div>
                    <div className={styles.dropdown}>
                        <Dropdown
                            className={styles.dropdown}
                            value={date}
                            setValue={setDate}
                            options={dateOptions}
                        />
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.list}>
                        {getSortedByDate(date, collections).map((collection, index) => (
                            isCollectionActive(collection.category?._id) &&
                            <Link className={styles.card} to={`/collections/${collection.slug}`} key={index}>
                                <img className={styles.banner} src={collection.banner} alt={collection.name}/>
                                <div className={styles.subtitle}>{collection.name}</div>
                                <div className={styles.line}>
                                    <div className={styles.user}>
                                        <div className={styles.avatar}>
                                            <img src={collection.user.avatar} alt={collection.user.name}/>
                                        </div>
                                        <div className={styles.author}>
                                            <Trans
                                                i18nKey="author"
                                                t={t}
                                                values={{name: collection.user.name}}
                                                components={[<span className={styles.authorName}/>]}
                                            />
                                        </div>
                                    </div>
                                    <div className={cn("status-stroke-black", styles.counter)}>
                                        <Trans
                                            i18nKey="counter"
                                            t={t}
                                            values={{counter: collection.itemsCount}}
                                            components={[<span/>]}
                                        />
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    {/* <div className={styles.btns}>
            <button className={cn("button-stroke", styles.button)}>
              <span>{t('loadMore')}</span>
            </button>
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default Collections;
