import React from "react";
import cn from "classnames";
import styles from "./FolowSteps.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import LoaderCircle from "../../../components/LoaderCircle";
import { Trans, useTranslation } from "react-i18next";

const FolowSteps = ({ className }) => {
  const { t } = useTranslation('ItemCreate');

  return (
    <div className={cn(className, styles.steps)}>
      <div className={cn("h4", styles.title)}>
        {t('FollowSteps.title')}
      </div>
      <div className={styles.list}>
        <div className={cn(styles.item, styles.done)}>
          <div className={styles.head}>
            <div className={styles.icon}>
              <Icon name="upload-file" size="24" />
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                {t('FollowSteps.stepOne.info')}
              </div>
              <div className={styles.text}>
                {t('FollowSteps.stepOne.text')}
              </div>
            </div>
          </div>
          <button className={cn("button done", styles.button)}>
            {t('FollowSteps.stepOne.button')}
          </button>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>
            <div className={styles.icon}>
              <Icon name="pencil" size="24" />
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                {t('FollowSteps.stepTwo.info')}
              </div>
              <div className={styles.text}>
                {t('FollowSteps.stepTwo.text')}
              </div>
            </div>
          </div>
          <button className={cn("button disabled", styles.button)}>
            {t('FollowSteps.stepTwo.button')}
          </button>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>
            <div className={styles.icon}>
              <LoaderCircle className={styles.loader} />
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                {t('FollowSteps.stepThree.info')}
              </div>
              <div className={styles.text}>
                {t('FollowSteps.stepThree.text')}
              </div>
            </div>
          </div>
          <button className={cn("button loading", styles.button)}>
            <Loader className={styles.loader} color="white" />
          </button>
        </div>
        <div className={cn(styles.item, styles.error)}>
          <div className={styles.head}>
            <div className={styles.icon}>
              <Icon name="pencil" size="24" />
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                {t('FollowSteps.stepFour.info')}
              </div>
              <div className={styles.text}>
                {t('FollowSteps.stepFour.text')}
              </div>
            </div>
          </div>
          <button className={cn("button error", styles.button)}>
            {t('FollowSteps.stepFour.button')}
          </button>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>
            <div className={styles.icon}>
              <Icon name="bag" size="24" />
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                {t('FollowSteps.stepFive.info')}
              </div>
              <div className={styles.text}>
                {t('FollowSteps.stepFive.text')}
              </div>
            </div>
          </div>
          <button className={cn("button", styles.button)}>
            {t('FollowSteps.stepFive.button')}
          </button>
        </div>
      </div>
      <div className={styles.note}>
        <Trans i18nKey="FollowSteps.note" t={t} components={[<a href="/#" target="_blank" rel="noopener noreferrer"/>]} />
      </div>
    </div>
  );
};

export default FolowSteps;
