import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./LanguageMenu.module.sass";
import Icon from "../../Icon";
import { EN_LANGUAGE_CODE, RU_LANGUAGE_CODE } from "../../../constants/i18n";

const languages = [
  {
    title: "English",
    value: EN_LANGUAGE_CODE
  },
  {
    title: "Русский",
    value: RU_LANGUAGE_CODE
  },
];

const LanguageMenu = ({ className }) => {
  const { i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  
  const saveLngInLS = (value) => {
    window.localStorage.setItem('nftLng', value);
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.languageMenu, className)}>
        <button
          className={styles.head}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="language" size="24" viewBox="0 0 24 24" />
        </button>
        {visible && (
          <div className={styles.body}>
            <div className={styles.list}>
              {languages.map((x, index) => (
                <div
                  className={cn(styles.item, {
                    [styles.itemActive]: x.value === i18n.language,
                  })}
                  to={x.url}
                  onClick={() => {
                    i18n.changeLanguage(x.value);

                    saveLngInLS(x.value);

                    setVisible(!visible);
                  }}
                  key={index}
                >
                  {x.title}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default LanguageMenu;
