import React from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import Identicon from "react-identicons";
import {shortenAddress} from "../../../utils/utils";

const Users = ({className, items}) => {
    return (
        <div className={cn(styles.users, className)}>
            <div className={styles.list}>
                {items.map((x, index) => (
                    <div className={styles.item} key={index}>
                        <div className={styles.avatar}>
                            <Identicon string={x.name} size={48} className={styles.identicon}/>
                            {x.reward && (
                                <div className={styles.reward}>
                                    <img src={x.reward} alt="Reward"/>
                                </div>
                            )}
                        </div>
                        <div className={styles.details}>
                            <div className={styles.position}>{x.position}</div>
                            <div className={styles.name}>{shortenAddress(x.name)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Users;
