import FavoriteItemRepository from "../repositories/FavoriteItemRepository";

const FavoriteItemService = {
    create(contractAddress, tokenId) {
        return FavoriteItemRepository.create({ contractAddress, tokenId });
    },

    getOne(contractAddress, tokenId) {
        return FavoriteItemRepository.getOne(contractAddress, tokenId);
    },

    getMany() {
        return FavoriteItemRepository.getMany();
    },

    delete(contractAddress, tokenId) {
        return FavoriteItemRepository.delete(contractAddress, tokenId);
    }
}

export default FavoriteItemService;