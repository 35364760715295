import React from "react";
import ReactDOM from "react-dom";
import './i18n';
import App from "./App";
import { ToastProvider } from "./providers/ToastProvider";
import { MoralisProvider } from "react-moralis";
import { MoralisDappProvider} from "./providers/MoralisDappProvider";
import { MeProvider } from "./providers/MeProvider";
import { CategoriesProvider } from "./providers/CategoriesProvider";
import { FavoriteItemsProvider } from "./providers/FavoriteItemsProvider";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const Application = () => {
    return (
        <ToastProvider>
            <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
                <MoralisDappProvider>
                    <MeProvider>
                        <CategoriesProvider>
                            <FavoriteItemsProvider>
                                <App/>
                            </FavoriteItemsProvider>
                        </CategoriesProvider>
                    </MeProvider>
                </MoralisDappProvider>
            </MoralisProvider>
        </ToastProvider>
    );
};

ReactDOM.render(
    <Application/>,
    document.getElementById("root")
);
