import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import {openInNewTab} from "../../../utils/utils";

const Details = ({ className, items }) => {
  return (
    <div className={cn(styles.details, className)}>
      {items.map((item, index) => (
        <div className={styles.item} key={index} onClick={()=>{
          if(item.link){
            openInNewTab(item.link)
          }
        }}>
          <div className={styles.label}>{item.label}</div>
          <div className={cn(styles.data, { [styles.dataMarked]: item.isMark })}>{item.data}</div>
        </div>
      ))}
    </div>
  );
}

export default Details;
