import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import Identicon from 'react-identicons';
import OutsideClickHandler from "react-outside-click-handler";
import { useMoralis } from "react-moralis";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider";
import { useNativeBalance } from "../../../hooks/useNativeBalance";
import { shortenAddress } from "../../../utils/utils";
import AuthService from "../../../services/AuthService";
import { useMeContext } from "../../../providers/MeProvider";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";

const UserAvatar = () => {
  const { me: { avatar, wallets } } = useMeContext();

  if (!avatar) {
    return <Identicon string={wallets?.ethAddress} size={32} className={styles.identicon}/>
  }

  return <img src={avatar} alt={wallets?.ethAddress} />;
}

const UserName = () => {
  const { me: { name } } = useMeContext();

  if (!name) {
    return null;
  }

  return <div className={styles.name}>{name}</div>;
}

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const { logout } = useMoralis();
  const { walletAddress } = useMoralisDapp();
  const { balance, nativeName } = useNativeBalance();
  const { t } = useTranslation('Header');

  const handleDisconnect = async (e) => {
    e.preventDefault();

    setVisible(!visible);

    try {
      await logout();

      await AuthService.logout();

      history.push('/');
    } catch(_) {}
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.avatar}>
            <UserAvatar />
          </div>
          <div className={styles.wallet}>
            {balance && ( parseFloat(balance.formatted.toFixed(4)))} <span className={styles.currency}>{nativeName}</span>
          </div>
        </div>
        {visible && (
          <div className={styles.body}>
            <UserName />
            <div className={styles.code}>
              <div className={styles.address}>{shortenAddress(walletAddress)}</div>
              <button className={styles.copy} onClick={() =>{
                navigator.clipboard.writeText(walletAddress)
                    .then(() => {
                      toast.success(t('User.walletAddress.copied'))
                    })
              }}>
                <Icon name="copy" size="16" />
              </button>
            </div>
            <div>
              <div className={styles.line}>
                <div className={styles.preview}>
                  <img
                    src="/icons/polygon-token.svg"
                    alt=""
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}>{t('User.balance.title')}</div>
                  <div className={styles.price}>{balance && (parseFloat(balance.formatted.toFixed(4)))} {nativeName}</div>
                </div>
              </div>
            </div>
            <div className={styles.menu}>
              <Link
                className={styles.item}
                to="/profile"
                onClick={() => setVisible(!visible)}
              >
                <div className={styles.icon}>
                  <Icon name={"user"} size="20" />
                </div>
                <div className={styles.text}>{t('User.profile')}</div>
              </Link>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <Icon name='bulb' size="20" />
                </div>
                <div className={styles.text}>{t('User.darkTheme')}</div>
                <Theme className={styles.theme} />
              </div>
              <a
                href="##"
                className={styles.item}
                onClick={(e) => handleDisconnect(e)}
              >
                <div className={styles.icon}>
                  <Icon name={"exit"} size="20" />
                </div>
                <div className={styles.text}>{t('User.disconnect')}</div>
              </a>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default User;
