import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Category.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import { useCategoriesContext } from "../../../providers/CategoriesProvider";
import {useTranslation} from "react-i18next";
import { EN_LANGUAGE_CODE } from "../../../constants/i18n";

const CategoriesList = (props) => {
  const { categories, isLoading, selected, onSelect } = props;
  const { i18n } = useTranslation('Header');

  if (isLoading) {
    return (
      <div className={styles.body}>
        <Loader />
      </div>
    );
  }

  if (!categories.length) {
    return (
      <div className={styles.body}>
        No categories
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.menu}>
        {categories.map((category, index) =>
          <div
            className={cn(styles.item, {
              [styles.itemActive]: selected === category._id
            })}
            onClick={() => onSelect(category._id)}
            key={index}
          >
            {category.icon && <Icon name={category.icon} size="20" className={styles.icon} />}
            <div className={styles.text}>{i18n.language === EN_LANGUAGE_CODE ? category.name : category.nameRu}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const Category = (props) => {
  const { className, selected, onSelect } = props;

  const [visible, setVisible] = useState(false);
  const { categories, isLoading } = useCategoriesContext();
  const { t } = useTranslation('CollectionCreate');

  const getName = (id) => {
    if (!id) {
      return t('categorySelect');
    }

    return categories.find((category) => category._id === id)?.name || t('categorySelect');
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className)}>
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <button
            type="button"
            className={cn("button-stroke button-small", styles.button)}
          >
            <Icon name="plus-circle" size="16" />
            <span>{getName(selected)}</span>
          </button>
        </div>
        {visible && <CategoriesList
          categories={categories}
          selected={selected}
          isLoading={isLoading}
          onSelect={(id) => {
            onSelect(id);
            setVisible(false);
          }}
        />}
      </div>
    </OutsideClickHandler>
  );
};

export default Category;
