import React, { useEffect, useState, createContext } from "react";
import { useMoralis } from "react-moralis";

export const MoralisDappContext = createContext();

function MoralisDappProvider({children}) {
    const {web3, Moralis, user} = useMoralis();
    const [walletAddress, setWalletAddress] = useState();
    const [chainId, setChainId] = useState();
    const contract = require("../contracts/marketplace-abi.json");
    const [contractABI, setContractABI] = useState(contract.abi);
    const [marketAddress, setMarketAddress] = useState('0x94a9F68192ccfB25E071Ac2ae4BDBd72a58dAfbB');
    const [minterAddress, setMinterAddress] = useState('0x7FdF7786Bd545FaF07d8A78162744783777595B1');
    const [marketItemsTable, setMarketItemsTable] = useState('MarketItemCreated');
    const [mintedNftTable, setMintedNftTable] = useState('ItemMinted');
    const [purchaseItemFunction, setPurchaseItemFunction] = useState("createMarketSale");
    const [listItemFunction, setListItemFunction] = useState("createMarketItem");

    async function checkNetwork() {
        const chainId = 137 // Polygon Mainnet

        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: web3.utils.toHex(chainId)}]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: 'Polygon Mainnet',
                                chainId: web3.utils.toHex(chainId),
                                nativeCurrency: {name: 'MATIC', decimals: 18, symbol: 'MATIC'},
                                rpcUrls: ['https://polygon-rpc.com/']
                            }
                        ]
                    });
                }
            }
        }
    }
    useEffect(() => {
        Moralis.onChainChanged(function (chain) {
            setChainId(chain);
        });

        Moralis.onAccountsChanged(function (address) {
            setWalletAddress(address[0]);
        });

        setChainId(web3.givenProvider?.chainId);
    }, [Moralis, web3.givenProvider?.chainId]);

    useEffect(async () => {
        await checkNetwork();
        setWalletAddress(web3.givenProvider?.selectedAddress || user?.get("ethAddress"));
    },
    [web3, user]);

    return (
        <MoralisDappContext.Provider
            value={{
                walletAddress,
                chainId,
                marketAddress,
                setMarketAddress,
                contractABI,
                setContractABI,
                purchaseItemFunction,
                minterAddress,
                marketItemsTable,
                mintedNftTable,
                listItemFunction
            }}>
            {children}
        </MoralisDappContext.Provider>
    );
}

function useMoralisDapp() {
    const context = React.useContext(MoralisDappContext);

    if (context === undefined) {
        throw new Error("useMoralisDapp must be used within a MoralisDappProvider");
    }

    return context;
}

export { MoralisDappProvider, useMoralisDapp };
