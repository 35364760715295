import {useEffect, useMemo, useState} from "react";
import {useMoralis, useMoralisWeb3Api, useMoralisWeb3ApiCall} from "react-moralis";
import {useMoralisDapp} from "../providers/MoralisDappProvider";

export const useNativeBalance = (options) => {
    const {account} = useMoralisWeb3Api();
    const {Moralis} = useMoralis();
    const {chainId, walletAddress} = useMoralisDapp();
    const [balance, setBalance] = useState({inWei: 0, formatted: 0});

    const nativeName = useMemo(() => "MATIC", [options, chainId]);

    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false);
    useEffect(async () => {
        if (!account || !chainId || !walletAddress) {
            return;
        }
        setIsLoading(true)

        const options = {
            chain: chainId,
            address: walletAddress,
        };
        try {
            const data = await Moralis.Web3API.account.getNativeBalance(options);
            setData(data);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false)
    }, [account, chainId, walletAddress])

    useEffect(() => {
        if (data?.balance) {
            const balances = {
                inWei: data.balance,
                formatted: Moralis.Units.FromWei(data.balance),
            };

            setBalance(balances);
        }
    }, [data]);

    return {balance, nativeName, isLoading};
};
