import React, {useEffect, useState} from "react";
import cn from "classnames";
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import styles from "./Support.module.sass";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Checkbox from "../../components/Checkbox";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import SupportService from "../../services/SupportService";
import {useToastContext} from "../../providers/ToastProvider";
import {Trans, useTranslation} from "react-i18next";

const socials = [
    {
        icon: 'instagram',
        url: 'https://instagram.com'
    },
    {
        icon: 'telegram',
        url: 'https://t.me',
        viewBox: '0 0 30 30'
    },
    {
        icon: 'facebook',
        url: 'https://facebook.com'
    },
];

const Support = () => {
    const [conditions, setConditions] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const toast = useToastContext();
    const {t, i18n} = useTranslation('Support');
    const [policy, setPolicy] = useState()

    useEffect(() => {
        if (!i18n) {
            return;
        }

        const lang = i18n.language;

        setPolicy(`/policy/kvell-${lang}.pdf`)

    }, [i18n])
    
    return (
        <div className={styles.page}>
            <div className={cn("section-pt80", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.top}>
                        <h1 className={cn("h2", styles.title)}>{t('title')}</h1>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <div className={styles.contactsItems}>
                                <div className={styles.contactsItem}>
                                    <div className={styles.contactsItemTitle}>
                                        {t('address.title')}
                                    </div>
                                    <div className={styles.contactsItemData}>
                                        <a
                                            href="https://goo.gl/maps/uiGND1quWiQR1XaF6"
                                            target="_blank"
                                            rel="noreferrer"
                                        >{t('address.data')}</a>
                                    </div>
                                </div>
                                <div className={styles.contactsItem}>
                                    <div className={styles.contactsItemTitle}>
                                        {t('email.title')}
                                    </div>
                                    <div className={styles.contactsItemData}>
                                        <a href="mailto:info@kvell.group">info@kvell.group</a>
                                    </div>
                                </div>
                                <div className={styles.contactsItem}>
                                    <div className={styles.contactsItemTitle}>
                                        {t('phone.title')}
                                    </div>
                                    <div className={styles.contactsItemData}>
                                        <a href="tel:+74951202250">+7 (495) 120-22-50</a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.socialsTitle}>{t('socials.title')}</div>
                            <div className={styles.socialsItems}>
                                {socials.map((x, index) => (
                                    <a
                                        href={x.url}
                                        className={styles.socialsLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        key={index}
                                    >
                                        <Icon name={x.icon} viewBox={x.viewBox}/>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className={styles.col}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    fullName: '',
                                    message: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().trim().required(t('emailField.errors.required')).email(t('emailField.errors.email')),
                                    fullName: Yup.string().trim().required(t('fullNameField.errors.required')),
                                    message: Yup.string().trim().required(t('messageField.errors.required')).min(10, t('messageField.errors.min', {min: 10})),
                                })}
                                onSubmit={async (values, {resetForm}) => {
                                    setIsSending(true);

                                    try {
                                        await SupportService.sendRequest(values);

                                        resetForm();

                                        setConditions(false);

                                        toast.success(t('toast.success'));
                                    } catch (_) {
                                        toast.error(t('toast.error'));
                                    }

                                    setIsSending(false);
                                }}
                            >
                                {props => {
                                    const {handleSubmit, submitCount, isSubmitting, isValid} = props;

                                    const isSubmitDisabled = isSubmitting || (!isValid && submitCount > 1) || !conditions;

                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <div className={styles.list}>
                                                <div className={styles.item}>
                                                    <div className={styles.fieldset}>
                                                        <TextInput
                                                            className={styles.field}
                                                            label={t('emailField.label')}
                                                            name="email"
                                                            type="email"
                                                            placeholder={t('emailField.placeholder')}
                                                        />
                                                        <TextInput
                                                            className={styles.field}
                                                            label={t('fullNameField.label')}
                                                            name="fullName"
                                                            type="text"
                                                            placeholder={t('fullNameField.placeholder')}
                                                        />
                                                        <TextArea
                                                            className={styles.field}
                                                            label={t('messageField.label')}
                                                            name="message"
                                                            placeholder={t('messageField.placeholder')}
                                                        />
                                                        <Checkbox
                                                            className={styles.checkbox}
                                                            value={conditions}
                                                            onChange={() => setConditions(!conditions)}
                                                            content={<Trans i18nKey="conditions" t={t}
                                                                            components={[<a href={policy}/>]}/>}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.buttonContainer}>
                                                <button className={cn("button", styles.button)} type="submit"
                                                        disabled={isSubmitDisabled}>
                                                    {t('submit')}
                                                </button>
                                                {isSending && <Loader className={styles.loader}/>}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
