import {useMoralisQuery} from "react-moralis";
import {useEffect, useState} from "react";
import {useMoralisDapp} from "../providers/MoralisDappProvider";

export const useNFTMarketData = (address, tokenId) => {
    const [NFTMarketData, setNFTMarketData] = useState(null);
    const [fetchSuccess, setFetchSuccess] = useState(true);
    const {marketItemsTable} = useMoralisDapp();

    const {
        fetch: getNFTMarketData,
        data,
        error,
        isLoading,
        isFetching
    } = useMoralisQuery(
        marketItemsTable,
        (query) => query.equalTo("nftContract", address).equalTo("tokenId", tokenId),
        [],
    );

    useEffect(() => {
        if (!data) {
            return;
        }

        const getItems = async () => {
            const fetchedMarketItems = JSON.parse(
                JSON.stringify(data, [
                    "updatedAt",
                    "price",
                    "nftContract",
                    "itemId",
                    "sold",
                    "tokenId",
                    "seller",
                    "owner",
                    "confirmed",
                    "objectId"
                ])
            );
            
            setFetchSuccess(true);
            setNFTMarketData(fetchedMarketItems.findLast(x => true));
        }

        getItems();
    }, [data]);

    return {getNFTMarketData, NFTMarketData, fetchSuccess, error, isLoading};
};
