import React from "react";
import cn from "classnames";
import styles from "./Burn.module.sass";
import { useTranslation } from "react-i18next";

const Burn = ({ className }) => {
  const { t } = useTranslation('Burn');

  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn("h4", styles.title)}>
        {t('title')}
      </div>
      <div className={styles.text}>
        {t('text')}
      </div>
      <div className={styles.btns}>
        <button className={cn("button-pink", styles.button)}>
          {t('continue')}
        </button>
        <button className={cn("button-stroke", styles.button)}>
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default Burn;
