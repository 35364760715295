import {Link, NavLink, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import User from "./User";
import ExploreMenu from "./ExploreMenu";
import {useMoralis} from "react-moralis";
import CreateMenu from "./CreateMenu";
import LanguageMenu from "./LanguageMenu";
import {useLocation} from "react-router";
import { useTranslation } from "react-i18next";

let nav = [
    {
        url: "/faq",
        title: "faq",
    },
    {
        url: "/item",
        title: "item",
    },
    {
        url: "/profile",
        title: "profile",
    },
];

const Headers = () => {
    const [visibleNav, setVisibleNav] = useState(false);
    const [search, setSearch] = useState("");
    const {authenticate, isAuthenticated} = useMoralis();
    const history = useHistory();
    const location = useLocation();
    const [showSearch, setShowSearch] = useState(true);
    const { t } = useTranslation('Header');

    useEffect(() => {
        setShowSearch(location.pathname !== '/search')
    }, [location])

    const handleSearch = (e) => {
        e.preventDefault();

        history.push('/search')
    };

    const handleConnectWallet = (e) => {
        e.preventDefault();

        authenticate();
    }

    return (
        <header className={styles.header}>
            <div className={cn("container", styles.container)}>
                <Link className={styles.logo} to="/">
                    <Image
                        className={styles.pic}
                        src="/images/logo-dark.png"
                        srcDark="/images/logo-light.png"
                        alt="Kvell.NFT"
                    />
                </Link>
                <div className={cn(styles.wrapper, {[styles.active]: visibleNav})}>
                    <nav className={styles.nav}>
                        <ExploreMenu/>
                        {nav.map((x, index) => (
                            <NavLink
                                className={(isActive) => cn(styles.link, {[styles.active]: isActive})}
                                to={x.url}
                                key={index}
                            >
                                {t(`nav.${x.title}`)}
                            </NavLink>
                        ))}
                    </nav>
                    <form
                        className={styles.search}
                        onSubmit={handleSearch}
                    >
                        <input
                            className={styles.input}
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            placeholder={t('searchField.placeholder')}
                        />
                        <button className={styles.result}>
                            <Icon name="search" size="20"/>
                        </button>
                    </form>
                    <CreateMenu className={styles.createMenu}/>
                </div>
                {/*<Notification className={styles.notification}/>*/}
                <LanguageMenu className={styles.notification}/>
                {
                    isAuthenticated
                        ?
                        (
                            <>
                                <CreateMenu className={styles.createMenu}/>
                                <User className={styles.user}/>
                            </>
                        )
                        :
                        (
                            <Link
                                to="#"
                                className={cn("button-stroke button-small", styles.button)}
                                onClick={handleConnectWallet}
                            >
                                {t('connectWallet')}
                            </Link>
                        )
                }
                <button
                    className={cn(styles.burger, {[styles.active]: visibleNav})}
                    onClick={() => setVisibleNav(!visibleNav)}
                />
            </div>
        </header>
    );
};

export default Headers;
