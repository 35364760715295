import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";
import styles from "./ExploreMenu.module.sass";
import { useCategoriesContext } from "../../../providers/CategoriesProvider";
import { useTranslation } from "react-i18next";
import { EN_LANGUAGE_CODE } from "../../../constants/i18n";

const CategoriesList = (props) => {
  const { categories, isLoading, onSelect } = props;
  const { t,i18n } = useTranslation('Header');


  if (isLoading) {
    return (
      <div className={styles.body}>
        <Loader />
      </div>
    );
  }

  if (!categories.length) {
    return (
      <div className={styles.body}>
        {t('ExploreMenu.noCategories')}
      </div>
    );
  }
  console.log(i18n.language)
  return (
    <div className={styles.body}>
      <div className={styles.menu}>
        {categories.map((category, index) =>

          <div
            className={styles.item}
            onClick={() => onSelect(category._id)}
            key={index}
          >
            {category.icon && <Icon name={category.icon} size="20" className={styles.icon}/>}
            <div className={styles.text}>{i18n.language ===EN_LANGUAGE_CODE ? category.name : category.nameRu}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExploreMenu = ({ className }) => {
  const [visible, setVisible] = useState(false);

  const history = useHistory();
  const { t } = useTranslation('Header');
  
  const { categories, isLoading } = useCategoriesContext();

  const handleSelect = (id) => {
    setVisible(false);

    history.push('/collections', { categoryId: id });
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.dropdown, className)}>
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          {t('ExploreMenu.title')}
        </div>
        {visible && <CategoriesList
          categories={categories}
          isLoading={isLoading}
          onSelect={(id) => handleSelect(id)}
        />}
      </div>
    </OutsideClickHandler>
  );
};

export default ExploreMenu;
