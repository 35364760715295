import {useEffect, useState} from "react";
import {useMoralisQuery} from "react-moralis";
import {useIPFS} from "./useIPFS";
import {useMoralisDapp} from "../providers/MoralisDappProvider";
import {Web3API} from "moralis";

export const useOnSaleItems = () => {
    const {chainId, walletAddress, marketItemsTable} = useMoralisDapp();
    const {resolveLink} = useIPFS();
    const [onSaleItems, setOnSaleItems] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const {
        fetch: getOnSaleItems,
        data,
        error,
    } = useMoralisQuery(
        marketItemsTable,
        (query) => query.equalTo("seller", walletAddress).equalTo("sold", false),
        [],
        {autoFetch: false}
    );

    useEffect(() => {
        if (!walletAddress) {
            return;
        }

        getOnSaleItems();

    }, [getOnSaleItems, walletAddress]);

    useEffect(() => {
        if (!data.length) {
            return;
        }

        const getItems = async () => {
            setIsLoading(true);

            const items = JSON.parse(
                JSON.stringify(data, [
                    "updatedAt",
                    "price",
                    "nftContract",
                    "itemId",
                    "sold",
                    "tokenId",
                    "seller",
                    "owner",
                    "confirmed",
                    "objectId"
                ])
            );

            const itemsWithMetadata = await Promise.all(items.map(async (item) => {
                const data = await Web3API.token.getTokenIdMetadata({
                    address: item.nftContract,
                    token_id: item.tokenId,
                    chain: chainId
                });
                const decodedData = JSON.parse(JSON.stringify(data));

                decodedData.price = item.price;

                if (decodedData?.metadata) {
                    decodedData.metadata = JSON.parse(decodedData.metadata);
                    decodedData.image = resolveLink(decodedData.metadata?.image);
                } else if (decodedData?.token_uri) {
                    try {
                        const response = await fetch(decodedData.token_uri);
                        const decodedResponse = await response.json();

                        decodedData.image = resolveLink(decodedResponse.image);
                    } catch (error) {
                        setFetchSuccess(false);
                    }
                }
                decodedData.marketData = item;

                return decodedData;
            }));

            setOnSaleItems(itemsWithMetadata);
            setIsLoading(false);
        }

        getItems();
    }, [data]);

    return {getOnSaleItems, onSaleItems, fetchSuccess, error, isLoading};
};
