import {useEffect, useState} from "react";
import {useMoralisQuery} from "react-moralis";
import {useIPFS} from "./useIPFS";
import {useMoralisDapp} from "../providers/MoralisDappProvider";
import {Web3API} from "moralis";

export const useCreatedItems = (options) => {
    const {chainId, walletAddress, minterAddress, mintedNftTable} = useMoralisDapp();
    const [createdItems, setCreatedItems] = useState([]);
    const {resolveLink} = useIPFS();

    const {
        fetch: getCreatedItems,
        data,
        error,
    } = useMoralisQuery(
        mintedNftTable,
        (query) => query.equalTo("owner", walletAddress),
        [],
        {autoFetch: true}
    );

    const [fetchSuccess, setFetchSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!data?.length) {
            return;
        }

        const getItems = async () => {
            setIsLoading(true);
            const items = data;
            const itemsWithMetadata = await Promise.all(items.map(async (item) => {
                console.error()
                const options = {
                    address: minterAddress,
                    token_id: JSON.parse(JSON.stringify(item)).tokenId.toString(),
                    chain: chainId
                };
                const data = await Web3API.token.getTokenIdMetadata(options);
                const decodedData = JSON.parse(JSON.stringify(data));

                decodedData.price = item.price;

                if (decodedData?.metadata) {
                    decodedData.metadata = JSON.parse(decodedData.metadata);
                    decodedData.image = resolveLink(decodedData.metadata?.image);
                } else if (decodedData?.token_uri) {
                    try {
                        const response = await fetch(decodedData.token_uri);
                        const decodedResponse = await response.json();

                        decodedData.image = resolveLink(decodedResponse.image);
                    } catch (error) {
                        setFetchSuccess(false);
                    }
                }

                return decodedData;
            }))

            setCreatedItems(itemsWithMetadata);
            setIsLoading(false);
        }

        getItems();
    }, [data]);

    return {getCreatedItems, createdItems, fetchSuccess, error, isLoading};
};
