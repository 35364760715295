import ItemRepository from "../repositories/ItemRepository";

const ItemService = {
    create(items, collectionId) {
        const data = items.map((item) => ({
            tokenId: item.token_id,
            contractAddress: item.token_address,
            collectionObj: collectionId,
        }))

        return ItemRepository.create(data);
    },

    getByCollectionId(collectionId){
        return ItemRepository.getMany(collectionId)
    },

    getBySearchQuery(searchQuery){
        return ItemRepository.search(searchQuery)
    },

    getOneWithData(contractAddress, tokenId) {
        return ItemRepository.getOneWithData(contractAddress, tokenId)
    }
}

export default ItemService;