import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
  
const lngFromLS = window.localStorage.getItem('nftLng') ?? 'en';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        lng: lngFromLS,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        defaultNS: 'common'
    });
  
export default i18n;
