import React from "react";
import cn from "classnames";
import {Link} from "react-router-dom";
import styles from "./Collections.module.sass";
import Loader from "../../../components/Loader";
import {useCollections} from "../../../hooks/useCollections";
import {useTranslation, Trans} from "react-i18next";
import Identicon from "react-identicons";

const CollectionsGrid = () => {
    const {collections, isLoading} = useCollections();
    const {t} = useTranslation('Home');

    if (isLoading) {
        return (
            <Loader className={styles.loader}/>
        );
    }

    if (!collections.length) {
        return (
            <div className={styles.empty}>{t('Collections.empty')}</div>
        );
    }

    return (
        <div className={styles.grid}>
            {collections.map((collection, index) => (
                <div key={index} className={styles.gridItem}>
                    <Link className={styles.item} to={`/collections/${collection.slug}`}>
                        <img className={styles.banner} src={collection.banner} alt={collection.name}/>
                        <div className={styles.subtitle}>{collection.name}</div>
                        <div className={styles.line}>
                            <div className={styles.user}>
                                <div className={styles.avatar}>
                                    {
                                        (collection.user.avatar) ? (<><img src={collection.user.avatar}
                                                                           alt={t('Avatar')}/></>)
                                            : (<><Identicon string={"test"} size={24}
                                                            className={styles.identicon}/></>)
                                    }


                                </div>
                                <div className={styles.author}>
                                    <Trans
                                        i18nKey="Collections.author"
                                        t={t}
                                        values={{name: collection.user.name}}
                                        components={[<span className={styles.authorName}/>]}
                                    />
                                </div>
                            </div>
                            <div className={cn("status-stroke-black", styles.counter)}>
                                <Trans
                                    i18nKey="Collections.counter"
                                    t={t}
                                    values={{counter: collection.itemsCount}}
                                    components={[<span/>]}
                                />
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}

const Collections = () => {
    const {t} = useTranslation('Home');

    return (
        <div className={cn("section-bg", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrapper}>
                    <h3 className={cn("h3", styles.title)}>{t('Collections.title')}</h3>
                    <div className={styles.inner}>
                        <CollectionsGrid/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Collections;
